import React, { useEffect, useState } from 'react';
import { Card, Table, Button } from 'antd';
import type { GetProp, TableProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { message, Row, Upload, Modal } from 'antd';
import {
  ReloadOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { commonuploadApiFunction } from './slice-upload';

const { Dragger } = Upload;

const props: UploadProps = {
  name: 'files',
  multiple: true,
  onDrop(e) {
    //console.log('Dropped files', e.dataTransfer.files);
  },
};

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {

  return (
    <div style={{maxWidth: '300px'}}>
      {/* hide error tooltip when dragging */}
      {file.status === 'error' ? originNode.props.children : originNode}
    </div>
  );
};



type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  id: string;
  filename: string;
  status: string;
  checksum: string;
  created: string;
  createdBy: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const columns: ColumnsType<DataType> = [
  {
    title: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'fileName',
    sorter: true,
    render: (name) => `${name}`,
    width: '20%',
  },
  {
    title: 'status',
    dataIndex: 'status',
    filters: [
      { text: 'Uplaoded', value: 'uploaded' },
      { text: 'Proccessed', value: 'proccedd' },
    ],
    width: '20%',
  },
  {
    title: 'created',
    dataIndex: 'created',
  },
  {
    title: 'uploadedBy',
    dataIndex: 'createdBy',
  }
];

type validateFile = (a: any) => boolean;

export const FileuploadCommon = ({ componentName, showlist, title, accept, extra, validateFile }: 
   { componentName: any, showlist?: boolean, title?: string,
    accept?: string, extra?: any, validateFile?: validateFile
}) => {
  const {data, tableParams, loading} = useSelector((state:any)=>state.commonupload)
  const LIMIT_PER_PAGE = 5;

  const { confirm } = Modal;

  const dispatch = useDispatch()
  useEffect(() => {
    fetchFiles()
  }, []);


  const fetchFiles  = (pageNo?:number) => {
    if (showlist)
    dispatch(commonuploadApiFunction({
      limit: LIMIT_PER_PAGE, page: pageNo || 1,
      componentName
    }))
  }

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    if (pagination.current !== tableParams.pagination?.current) {
      fetchFiles(pagination.current)
    }
  };


  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    
  }

  let resolver:any = [];
  let ignoreid:any = []
  return (
    <>
    <Row>
      <Dragger   {...props} 
      accept={accept}
      openFileDialogOnClick={true}
      fileList={fileList}
      beforeUpload={(file: any, list: any)=> new Promise((resolve, reject) => { 
          
        if (validateFile) {
          if (!validateFile(file)) {
            reject();
            return;
          }
        }
        resolver.push({ resolve, reject });
          if (list[0].uid === file.uid){
              confirm({
                title: 'Kindly confirm to upload?',
                content:
                  'Click ok to procced or click cancel to abort',
                onOk() {
                  resolver.forEach((el:any) => {
                    el.resolve();
                  });
                  resolver = []
                  
                },
                onCancel() {
                  resolver.forEach((el: any) => {
                    el.reject(Upload.LIST_IGNORE);
                  });
                  resolver = []
                },
              });
            }
      })
    }
     itemRender={(originNode, file) => (
            <DraggableUploadListItem originNode={originNode} file={file} />
      )}
      style={{maxHeight: '100px', minWidth: '300px'}} 
      action={`${process.env.REACT_APP_BASE_URL}upload?componentName=${componentName}&extra=${extra?extra: ''}`}
      headers={{ 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'ext': 'ds' }}
      onChange = {(info) =>{
        if (info.file.status === undefined) {
          ignoreid.push(info.file.uid)
        }
        setFileList(info.fileList.filter(e => ignoreid.indexOf(e.uid) < 0 ))
        const {status} = info.file;
          if (status === 'done') {
                if (showlist) {fetchFiles();}
                message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
                message.error(`${info.file.response.error} : ${info.file.name} `);
          }
      }}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined /> {title?title:'upload file'}
        </p>
      </Dragger>
    </Row>
      {showlist?
      <Card title="Uploaded Files" extra={<Button onClick={e => fetchFiles()} loading={loading} ><ReloadOutlined />  Refresh</Button>} >
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
        />
      </Card >: null}
    </>
  );
};
