import React, { useState } from 'react';
import CardBox from '../common/CardBox';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import SearchOperation from './search-operation';
import OperationData from './operation-data';
import SearchOperationDetails from './search-operations-detail';

export const Operation = () => {
    return <CardBox title={<div><span>Operations</span><span className="highlightBorder"></span></div>}>
        <Card>
            <SearchOperationDetails />
        </Card>
        <Card>
            <OperationData />
        </Card>
    </CardBox>
}

export default Operation;