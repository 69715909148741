import React, { useEffect } from "react";
import { ConfigProvider, theme, message } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import {
  createHashRouter,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkLogin } from "./component/security/slice-login";
import { routeDetail } from "./route";
import { ArgsProps, MessageType } from "antd/es/message/interface";

const errorMessageSubscribers = {} as any;

export const popMessage = (message: ArgsProps) => {
  var subscriber = Object.keys(errorMessageSubscribers);
  subscriber.forEach((e) => errorMessageSubscribers[e](message));
};

//default flow
const RedirectFlow = () => {
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      nav("/home");
    }
  }, []);
  return <Outlet />;
};

const router = createHashRouter(routeDetail as any);

const App: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  useEffect(() => {
    errorMessageSubscribers["subscriber"] = (mesage: ArgsProps) =>
      messageApi.open(mesage);
    dispatch(checkLogin(""));
  }, []);
  return (
    <ConfigProvider
      menu={{ className: "efficient-theme-light-menu" }}
      layout={{ className: "efficient-theme-light-layout" }}
      prefixCls="eff"
      theme={{
        cssVar: true,
        hashed: false,
        algorithm: theme.compactAlgorithm,
      }}
    >
      <StyleProvider hashPriority="high">
        {contextHolder}
        <RouterProvider router={router} />
      </StyleProvider>
    </ConfigProvider>
  );
};

export default App;
