import React, { useEffect, useState } from 'react';
import CommonDataTable from '../../common/CommonDataTable';

export const EditClientCollection = () => {
    return (
        <>
            <CommonDataTable />
        </>
    )
}

export default EditClientCollection;
