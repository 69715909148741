import React, { useEffect, useState } from 'react';
import type { GetProp, TableColumnsType, TableProps } from 'antd';
import { Button, Table } from 'antd';
import type { SorterResult } from 'antd/es/table/interface';
import qs from 'qs';
import { EditOutlined, DeleteOutlined, DownloadOutlined, MoreOutlined } from '@ant-design/icons';
type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType { 
  booking_number: string;
  email: string;
  customer_name: string;
  createdOn: string;
  createdBy:string;
  bookingStatus:string;
  action:string
}
const columns: TableColumnsType<DataType> = [
  {
    title: "Booking Number",
    dataIndex: "booking_number",
    sorter: true,
    render: (booking_number:string) => `${booking_number}`, 
  },
  {
    title: "Exc Qty.",
    dataIndex: "qty",
    sorter: true, 
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: true,
  },
  {
    title: "Pickup",
    dataIndex: "pickup",
    sorter: true,
  },
  {
    title: "Adult",
    dataIndex: "adult",
    sorter: true,
  },
  {
    title: "Child",
    dataIndex: "child",
    sorter: true,
  },
  {
    title: "Infant",
    dataIndex: "infant",
    sorter: true,
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: true,
  },
  {
    title: "Deduction",
    dataIndex: "deduction",
    sorter: true,
  },
  {
    title: "Addition",
    dataIndex: "action", 
    sorter: true,
  },
];
const dataShare: DataType[] = [
  {
    booking_number: '2501241919',
    email: 'jhonwick@doe.com',
    customer_name: 'John Doe',
    createdOn: '04.07.2019',
    createdBy: 'Josef Roy',
    bookingStatus: 'Booking Created',
    action: ''
  },
  {
    booking_number: '2501241919',
    email: 'jhonwick@doe.com',
    customer_name: 'John Doe',
    createdOn: '04.07.2019',
    createdBy: 'Josef Roy',
    bookingStatus: 'Booking Created',
    action: ''
  },
  // { 
  //   booking_number: '2501241919',
  //   email: 'jhonwick@doe.com',
  //   address: 'Sydney No. 1 Lake Park',
  // },
  // { 
  //   booking_number: '2501241919',
  //   email: 'jhonwick@doe.com',
  //   address: 'Sydney No. 1 Lake Park',
  // },
];

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<any>['field'];
  sortOrder?: SorterResult<any>['order'];
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

// const columns: ColumnsType<DataType> = [

// ];

const getRandomuserParams = (params: TableParams) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const CommonDataTable: React.FC<any> = ({ onRowClick }) => {
  const [data, setData] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ['bottomCenter']
    },
  });

  const fetchData = () => {
    setLoading(true);
    fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
      .then((res) => res.json())
      .then(({ results }) => {
        setData(results);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: 200,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      });
  };

  useEffect(fetchData, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);

  const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <Table<DataType>
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {onRowClick(event)}, // click row
        };
      }}
      columns={columns}
      dataSource={dataShare}
      pagination={tableParams.pagination}
      loading={loading}
      className='fullWidthDataTable'
      onChange={handleTableChange}
      scroll={{ x: 800 }} 
    />
  );
};

export default CommonDataTable;