import React, { useState } from "react";
import type { CascaderProps } from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Select,
  Flex,
} from "antd";
import CommonDataTable from "../../../common/CommonDataTable";

const { Option } = Select;

interface DataNodeType {
  value: string;
  label: string;
  children?: DataNodeType[];
}

const residences: CascaderProps<DataNodeType>["options"] = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const OTBService: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const namePrefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">Mr.</Option>
        <Option value="87">Ms.</Option>
      </Select>
    </Form.Item>
  );

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        className="mainCardDetailsForm createCustomerForm"
        initialValues={{
          residence: ["zhejiang", "hangzhou", "xihu"],
          prefix: "86",
        }}
        scrollToFirstError
      >
        <Form.Item className="cardTextfieldBox" name="otbType" label="OTB Type">
          <Select placeholder="select Tour Type" className="cardInputfield">
            <Option value="male">Normal</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Travel Date" className="cardTextfieldBox">
          <DatePicker style={{ width: "100%" }} className="cardInputfield" />
        </Form.Item>

        <Form.Item className="cardTextfieldBox" name="name" label="Name">
          <Input className="cardInputfield" />
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox"
          name="passNo"
          label="Passport Number"
        >
          <Input className="cardInputfield" />
        </Form.Item>

        <Form.Item className="cardTextfieldBox" name="country" label="Country">
          <Select placeholder="select Country" className="cardInputfield">
            <Option value="male">UAE</Option>
            <Option value="female">Saudi Arabia</Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox"
          name="visaType"
          label="Visa Type"
        >
          <Select placeholder="select Country" className="cardInputfield">
            <Option value="male">Tourist</Option>
            <Option value="female">Work</Option>
          </Select>
        </Form.Item>

        <div className="cardTextfieldBox timingInfoBox multipleTextfield">
          <Flex gap={15}>
            <Form.Item
              className=""
              name="price"
              label="Price"
              style={{ flex: 1 }}
            >
              <Input className="cardInputfield" />
            </Form.Item>

            <Form.Item
              className=""
              name="pnr"
              label="PNR"
              style={{ flex: 1 }}
            >
              <Input className="cardInputfield" />
            </Form.Item>
          </Flex>
        </div>

        <Form.Item {...tailFormItemLayout} className="bottomButtonBox w100">
          <Button type="default" htmlType="reset">
            Clear
          </Button>
          <Button type="primary" htmlType="submit">
            Add Service
          </Button>
        </Form.Item>
      </Form>
      <CommonDataTable />
    </>
  );
};

export default OTBService;
