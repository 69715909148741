import { Card } from "antd";

export const CardBox = ({
  title,
  extra,
  children,
  border,
  background = "white",
}: any) => {
  return (
    <Card
      className="mainCard"
      title={title}
      extra={extra}
      bordered={border !== false}
      style={{ background: background }}
    >
      {children}
    </Card>
  );
};
export default CardBox;