import React, { useEffect, useState } from 'react';
import { 
    message,
    Steps,
    theme,
    Button,
    Checkbox,
    Form,
    Input,
    DatePicker,
    Select,
 } from 'antd';

 const { Option } = Select;

const AirlineTicketItinerary: React.FC = () => {
    const [form] = Form.useForm();
    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      };
    
      const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
      };
    
      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="86">+86</Option>
            <Option value="87">+87</Option>
          </Select>
        </Form.Item>
      );
    
      const namePrefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="86">Mr.</Option>
            <Option value="87">Ms.</Option>
          </Select>
        </Form.Item>
      );
    
      const suffixSelector = (
        <Form.Item name="suffix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="USD">$</Option>
            <Option value="CNY">¥</Option>
          </Select>
        </Form.Item>
      );
      
    const flightInformation = (<Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        className="mainCardDetailsForm"
        initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
        scrollToFirstError
      >
        <Form.Item className="cardTextfieldBox" name="city" label="City">
          <Input className="cardInputfield" />
        </Form.Item>

        <Form.Item className="cardTextfieldBox" name="bookingType" label="Tour Type">
          <Select placeholder="select Tour Type" className="cardInputfield">
            <Option value="male">Private</Option>
            <Option value="female">Booking</Option>
            <Option value="other">Seat in Couch</Option>
            <Option value="other">Non Operational Product</Option>
          </Select>
        </Form.Item>
        <Form.Item className="cardTextfieldBox" name="bookingType" label="Booking Type">
          <Select placeholder="select Booking Type" className="cardInputfield">
            <Option value="male">Tours</Option>
            <Option value="female">Tickets</Option>
          </Select>
        </Form.Item>

        <Form.Item className="cardTextfieldBox" name="tou" label="Tour">
          <Select placeholder="select Tour" className="cardInputfield">
            <Option value="male">Abu Dhabi City Tour</Option>
            <Option value="female">Dubai Half Day Tour</Option>
          </Select>
        </Form.Item>

        <Form.Item className="cardTextfieldBox" name="ticket" label="Ticket">
          <Select placeholder="select Ticket" className="cardInputfield">
            <Option value="male">Ferrari Car World</Option>
            <Option value="female">Amusement Park Ticket</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Tour Date" className="cardTextfieldBox">
          <DatePicker style={{ width: '100%' }} className="cardInputfield" />
        </Form.Item>
        <Form.Item className="cardTextfieldBox buttonFieldBox checkboxBottomLabelBox" name="timeslot" label="Select Time slot">
          <Input className="cardInputfield" />
          <Button type="primary" htmlType="submit">
            Slot
          </Button>
          <div className="checkboxLabelBottom">
            <Checkbox className="cardTextfieldBox">Real Time</Checkbox>
          </div>
        </Form.Item>
        <Form.Item className="cardTextfieldBox" name="supplier" label="Supplier">
          <Select placeholder="select Supplier" className="cardInputfield">
            <Option value="male">Rayna Tourism</Option>
            <Option value="female">Effecient Tourism</Option>
          </Select>
        </Form.Item>
        <div className="cardTextfieldBox dFlex multipleTextfield">
          <Form.Item className="cardTextfieldBox" name="adult" label="Adult">
            <Input className="cardInputfield" />
          </Form.Item>
          <Form.Item className="cardTextfieldBox" name="child" label="Child">
            <Input className="cardInputfield" />
          </Form.Item>
          <Form.Item className="cardTextfieldBox" name="infant" label="Infant">
            <Input className="cardInputfield" />
          </Form.Item>
        </div>
      </Form>);

    const pricing = (<Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        className="mainCardDetailsForm"
        initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
        scrollToFirstError
    >
        <Form.Item className="cardTextfieldBox" name="adult" label="Adult">
        <Select placeholder="select Supplier" className="cardInputfield">
            <Option value="male">1</Option>
        </Select>
        </Form.Item>
        <div className="cardTextfieldBox dFlex multipleTextfield">
        <Form.Item className="cardTextfieldBox" name="costPrice" label="Cost Price">
            <Input className="cardInputfield" />
        </Form.Item>
        <Form.Item className="cardTextfieldBox" name="sellingPrice" label="Selling Price">
            <Input className="cardInputfield" />
        </Form.Item>
        </div>
    </Form>);

const PassengerDetails = (<Form
    {...formItemLayout}
    form={form}
    name="register"
    onFinish={onFinish}
    className="mainCardDetailsForm"
    initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
    scrollToFirstError
>
    <Form.Item className="cardTextfieldBox" name="city" label="City">
    <Input className="cardInputfield" />
    </Form.Item>

    <Form.Item className="cardTextfieldBox" name="bookingType" label="Tour Type">
    <Select placeholder="select Tour Type" className="cardInputfield">
        <Option value="male">Private</Option>
        <Option value="female">Booking</Option>
        <Option value="other">Seat in Couch</Option>
        <Option value="other">Non Operational Product</Option>
    </Select>
    </Form.Item>
    <Form.Item className="cardTextfieldBox" name="supplier" label="Supplier">
    <Select placeholder="select Supplier" className="cardInputfield">
        <Option value="male">Rayna Tourism</Option>
        <Option value="female">Effecient Tourism</Option>
    </Select>
    </Form.Item>
</Form>);

    const steps = [
        {
          title: 'Flight Information',
          content: flightInformation,
        },
        {
          title: 'Pricing',
          content: pricing,
        },
        {
          title: 'Passenger Details',
          content: PassengerDetails,
        },
      ];  
      

    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const next = () => {
      setCurrent(current + 1);
    };
    const prev = () => {
      setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
      key: item.title,
      title: item.title,
    }));
    return (
      <>
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
      </>
    );
  };

export default AirlineTicketItinerary;