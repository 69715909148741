import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../../utils/httpThunkClient'
import { useStore } from 'react-redux'



// First, create the thunk
export const userlistApiFunction = ThunkHttpClient.get('users/list', () =>  "users/list")
export const usercreateApiFunction = ThunkHttpClient.post('user/create', (body) => ({url:"users/create", body}))

export const userroleuppdateApiFunction = ThunkHttpClient.post('user/updateRole', (body) => ({url:"users/updateRole", body}))

export const markactiveinactive = ThunkHttpClient.post('user/markactiveinactive', (body) => ({url:"users/markactiveinactive", body}))

export const savePermission = ThunkHttpClient.post('user/savepermission', (body) => ({url:"users/userpermissions", body}))

export const assignManager = ThunkHttpClient.get('users/permissionassignment', (data) =>  `users/managerassign?userid=${data.userid}&managerid=${data.managerid}`)



// Define the initial state using that type
const initialState: any = {
    loading: false,
    selected: undefined,
    data: [],
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        selectUser: (state, action) => {
            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
       
        builder.addCase(userlistApiFunction.fulfilled, (state, action) => {
            state.loading = false;
            if (action && action.payload)
            state.data = action.payload.data
        })
        builder.addCase(userlistApiFunction.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(userlistApiFunction.rejected, (state, action) => {
            state.loading = false;
        })

        builder.addCase(usercreateApiFunction.fulfilled, (state, action) => {
            state.createloading = false;
           // popMessage({type: 'success', content: 'User created succesfully'})
        })
        builder.addCase(usercreateApiFunction.pending, (state, action) => {
            state.createloading = true;
        })
        builder.addCase(usercreateApiFunction.rejected, (state, action) => {
            state.createloading = false;
        })

        builder.addCase(savePermission.fulfilled, (state, action) => {
            state.permissionloadingsave = false;
           // popMessage({type: 'success', content: 'User created succesfully'})
        })
        builder.addCase(savePermission.pending, (state, action) => {
            state.permissionloadingsave = true;
        })
        builder.addCase(savePermission.rejected, (state, action) => {
            state.permissionloadingsave = false;
        })

        
        builder.addCase(assignManager.fulfilled, (state, action) => {
            state.managerassignloading = false;
           // popMessage({type: 'success', content: 'User created succesfully'})
        })
        builder.addCase(assignManager.pending, (state, action) => {
            state.managerassignloading = true;
        })
        builder.addCase(assignManager.rejected, (state, action) => {
            state.managerassignloading = false;
        })


        

    },
})




export const {selectUser} = userSlice.actions
export default userSlice.reducer;


