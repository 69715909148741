import React, { useState } from "react";
import {
  GroupOutlined,
  FileOutlined,
  HomeOutlined,
  UploadOutlined,
  UserOutlined,
  ProductOutlined,
  OrderedListOutlined,
  SyncOutlined,
  BarChartOutlined,
  ControlOutlined,
  FileTextOutlined,
  UnorderedListOutlined,
  DownloadOutlined,
  UsergroupAddOutlined,
  CodeOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, theme } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HeaderComponent } from "./common/Header";
import { PreAuthz, preAuthzFn } from "./security/Authz";
import { title } from "process";
import SubMenu from "antd/es/menu/SubMenu";
import { useSelector } from "react-redux";
import {
  Books,
  ChartArcs,
  ChartArrows,
  ChartDots,
  Dashboard,
} from "../ImportData";

const { Content, Sider } = Layout;

const urlrolemap: any = {};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: any[],
  permittedRoles?: any
): any {
  urlrolemap["" + key] = permittedRoles;
  return {
    key,
    icon,
    children,
    label,
    permittedRoles,
  };
}

function getItemdd(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: any[],
  permittedRoles?: any
): any {
  urlrolemap["" + key] = permittedRoles;
  return permittedRoles ? (
    <PreAuthz hideResult={true} allowedRoles={permittedRoles}>
      {children ? (
        <SubMenu icon={icon} title={label}>
          {children}
        </SubMenu>
      ) : (
        <Menu.Item key={key} icon={icon} title={label}>
          {label}
        </Menu.Item>
      )}
    </PreAuthz>
  ) : (
    <>
      {children ? (
        <SubMenu icon={icon} title={label}>
          {children}
        </SubMenu>
      ) : (
        <Menu.Item key={key} icon={icon} title={label}>
          {label}
        </Menu.Item>
      )}
    </>
  );

  // {
  //   key,
  //   icon,
  //   children,
  //   label
  // };
}

const items: any[] = [
  getItem(
    "Dashboard",
    "/home",
    <Dashboard />,
    undefined,
    "ticketmanager,ticketadmin,superadmin"
  ),
  getItem("Booking", "/home/booking", <ChartArcs />, undefined),
  getItem("Master", "/home/master/home", <Books />),
  getItem("Accounting", "/home/accounting", <ChartArrows />, [
    getItem(
      "Accounting Dashboard",
      "/home/accounting/",
      <UsergroupAddOutlined />
    ),
    getItem("Invoice", "/home/accounting/invoices", <UsergroupAddOutlined />),
    getItem("Banking", "/home/accounting/banking", <UsergroupAddOutlined />),
    getItem("Expenses", "/home/accounting/expenses", <UsergroupAddOutlined />),
    getItem("Reports", "/home/accounting/reports", <BarChartOutlined />),
    getItem(
      "Documents",
      "/home/accounting/documents",
      <UsergroupAddOutlined />
    ),
  ]),
  getItem("HRMS", "", <ChartDots />, [
    getItem(
      "Oraganization Hierarchy",
      "/home/hrms/organization/hierarchy",
      <UsergroupAddOutlined />
    ),
    getItem(
      "Permission Assignment",
      "/home/hrms/permissionassignment",
      <UsergroupAddOutlined />
    ),
  ]),
  getItem("Users", "/home/user", <UserOutlined />, undefined, "superadmin"),
  getItem(
    "Company",
    "/home/company",
    <GroupOutlined />,
    undefined,
    "systemadmin"
  ),
  getItem(
    "Ticket Inventory",
    "",
    <ProductOutlined />,
    [
      getItem(
        "Upload List",
        "/home/ticketinventory/list",
        <UploadOutlined />,
        undefined,
        "superadmin,ticketmanager"
      ),
      getItem("List", "/home/ticketinventory/list", <OrderedListOutlined />),
      getItem(
        "Product List",
        "/home/ticketinventory/productlist",
        <OrderedListOutlined />
      ),
      getItem(
        "Download",
        "/home/ticketinventory/download",
        <DownloadOutlined />
      ),
    ],
    "ticketmanager,ticketadmin,superadmin"
  ),
  getItem(
    "Itinerary",
    "",
    <FileTextOutlined />,
    [
      getItem("Itinerary Product", "/home/itinerary/list", <SyncOutlined />),
      getItem(
        "Itinerary Quotations",
        "/home/itinerary/quotationlist",
        <UnorderedListOutlined />
      ),
    ],
    "ticketmanager,ticketadmin,superadmin"
  ),

  getItem("System Master", "/home/system/master", <ControlOutlined />),

  getItem("Operation", "/home/operation", <FileOutlined />),
];

const Home = () => {
  const nav = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const assign = urlrolemap[location.pathname];
  const { userinfo, data } = useSelector((state: any) => state.login);

  const renderItem = (e: any) => {
    if (e.permittedRoles) {
      if (preAuthzFn(e.permittedRoles, userinfo?.userRole)) {
        return (
          <Menu.Item className="leftMenuItem" icon={e.icon} key={e.key}>
            {e.label}
          </Menu.Item>
        );
      }
      return null;
    }
    return (
      <Menu.Item className="leftMenuItem" icon={e.icon} key={e.key}>
        {e.label}
      </Menu.Item>
    );
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          width={250}
          style={{ height: "100vh" }}
          theme="light"
          className="leftPanelMenu"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="slidePanelLogoBox">
            <img
              src="https://cdn.efficienttourism.com/wp-content/uploads/2022/09/logo-300.png"
              style={{ width: "calc(100% - 40px)" }}
            />
          </div>
          <div className="slidePanelLogoCollapsed">
            <span></span>
          </div>
          <Menu
            defaultSelectedKeys={[location.pathname]}
            mode="inline"
            onClick={(e) => {
              if (e.key !== "") nav(e.key);
            }}
          >
            {items.map((e) => {
              if (e.children && e.children.length > 0) {
                if (
                  e.permittedRoles &&
                  !preAuthzFn(e.permittedRoles, userinfo?.userRole)
                ) {
                  return null;
                }
                return (
                  <Menu.SubMenu
                    className="leftMenuItem"
                    key={e.label + "ch"}
                    icon={e.icon}
                    title={e.label}
                  >
                    {e.children.map((ch: any) => renderItem(ch))}{" "}
                  </Menu.SubMenu>
                );
              }
              return renderItem(e);
            })}
          </Menu>
        </Sider>
        <Layout style={{ height: "100vh", overflowY: "auto" }}>
          <HeaderComponent setCollapsed={setCollapsed} collapsed={collapsed} />
          <Content
            style={{
              background: "#f8f8f8",
              borderRadius: 0,
            }}
          >
            {assign ? (
              <PreAuthz allowedRoles={assign}>
                <Outlet />
              </PreAuthz>
            ) : (
              <Outlet />
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export { Home };
