import React, { useEffect, useState } from 'react';
import CommonDataTable from '../../common/CommonDataTable';
import { Button, Col, Row } from 'antd';
import { CloseOutlined, RollbackOutlined } from '@ant-design/icons';

export const EditTourDetails = () => {
    return (
        <div className="detailsSection">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='mb-4'>
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Name</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Jhon Wick</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Residense Type</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Residense</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>VIP</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>-</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Email</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>JhonWick@company.com</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Gurantee</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Cash</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Nationality</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Saudi Arabia</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Address</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Address</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Company</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Efficient Tourism</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Designation</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>-</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Street</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Test</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>City</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>Dubai</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Postal Code</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>234233</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Contact number</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>+45 2238923472</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>ID Number</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>2983723782</span>
                    </div>
                </Col>
                {/*  */}
                <Col span={4}>
                    <div className="viewLabel">
                        <span>Secondary Contact Number</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="viewValue">
                        <span>-</span>
                    </div>
                </Col>
                {/*  */}
            </Row>
            <CommonDataTable/> 
        </div>
    )
}

export default EditTourDetails;
