import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkHttpClient } from '../utils/httpThunkClient'


// First, create the thunk
export const getAllMasterType = ThunkHttpClient.get('master/getAllMasterType', () => ("master/getAllMasterType"))
export const getMasterTypeValue = ThunkHttpClient.get('master/getMasterTypeValue', (data) => (`master/getMasterTypeValue?masterType=${data}`))
export const getMasterTypeSelectorValue = ThunkHttpClient.get('master/getMasterSelectorTypeValue', (data) => (`master/getMasterTypeValue/active?masterType=${data}`))
export const getStaticDropdownData = createAsyncThunk("masterstatic/staticdropdown", async (data: any, { dispatch }) => {
      const datad = await fetch('/assets/dropdown.json', {
        method: 'GET', // or 'PUT'
        headers: { "Content-Type": "application/json" }
      })
      const  dd =  datad.json()
      return dd;
})

export const saveOrUpdateMasterValues = ThunkHttpClient.post('master/saveOrUpdateMasterValues', (body) => ({url:'master/saveOrUpdateMasterValues', body}))

// Define the initial state using that type
const initialState: any = {
  masterTypeData: {
    masterType: []
  },
  loading: false,
  count: 0,
  dropdownstaticdata: {},
  masterTypeValues: [],
  masterSelectValuesLoading: {},
  masterSelectValues: {}
}

export const counterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {

    builder.addCase(getMasterTypeSelectorValue.fulfilled, (state, action) => {
      state.masterSelectValuesLoading[action.meta.arg] = false;
      if (action.payload) {
        state.masterSelectValues[action.meta.arg] = action.payload.data;
        if(action.meta.arg === 'Trip Ticket Inventory') {
          state.masterSelectValuesMap = {};
          state.masterSelectValues[action.meta.arg].forEach((element: any) => {
            
            element.extra = JSON.parse(element.other || '{}')
            state.masterSelectValuesMap[element.id] = element;
          });
          
        }
      }
    })
    builder.addCase(getMasterTypeSelectorValue.pending, (state, action) => {
      state.masterSelectValuesLoading[action.meta.arg] = true;
    })
    builder.addCase(getMasterTypeSelectorValue.rejected, (state, action) => {
      state.masterSelectValuesLoading[action.meta.arg] = false;
    })

    builder.addCase(getAllMasterType.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload)
        state.masterTypeData = action.payload.data;
    })
    builder.addCase(getAllMasterType.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getAllMasterType.rejected, (state, action) => {
      state.loading = false;
    })

    builder.addCase(getMasterTypeValue.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload)
        state.masterTypeValues = action.payload.data;
    })
    builder.addCase(getMasterTypeValue.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getMasterTypeValue.rejected, (state, action) => {
      state.loading = false;
    })

    builder.addCase(getStaticDropdownData.fulfilled, (state, action) => {
      state.dropdownstaticloading = false;
      
      if (action.payload)
        state.dropdownstaticdata = action.payload;
    })
    builder.addCase(getStaticDropdownData.pending, (state, action) => {
      state.dropdownstaticloading = true;
    })
    builder.addCase(getStaticDropdownData.rejected, (state, action) => {
      state.dropdownstaticloading = false;
    })

    

    builder.addCase(saveOrUpdateMasterValues.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload)
        state.count = ++state.count; 
    })
    builder.addCase(saveOrUpdateMasterValues.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(saveOrUpdateMasterValues.rejected, (state, action) => {
      state.loading = false;
      state.count = ++state.count; 
    })


  },
})

export default counterSlice.reducer