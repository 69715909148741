import React, { useEffect, useState } from "react";
import { Button, Card, Col, GetProp, List, Row, Table, Typography } from "antd";
import { useDispatch } from "react-redux";
import { productWiseCountReport } from "./slice-ticketInventory";
import { truncate } from "fs";
import ReactECharts from "echarts-for-react";
import { Link } from "react-router-dom";
import CardBox from "../common/CardBox";
import { ColumnsType, TableProps } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
// Register the required components

const { Text } = Typography;

const map = {
  uploadedpdfadult: "Uploaded Pdf Adult",
  uploadedpdfchild: "Uploaded Pdf Child",
  uploadedmediachild: "Uploaded Media Child",
  uploadedmediaadult: "Uploaded Media Adult",
  availpdfadult: "Available Pdf Adult",
  availpdfchild: "Available Pdf Child",
  availmediaadult: "Available Media Child",
  availmediachild: "Available Media Adult",
} as any;

const baroption = {
  color: [],
  tooltip: {
    extraCssText: "z-index: 23232323",
    position: ["30%", "30%"],
  },
  legend: {
    orient: "vertical",
    left: "left",
    lineHeight: 30,
    itemWidth: 20,
    textStyle: {
      width: 70,
      fontSize: 10,
      overflow: "truncate",
    },
    nameTruncate: {
      maxWidth: 10,
      ellipsis: "...",
    },
  },
  series: [],
} as any;

const getOptionVp = (data: any, name: string, avail = false) => {
  if (data == null || data === undefined) {
    return {};
  }
  const oo = { ...baroption };
  oo.color = !avail
    ? ["#8a1e1d", "#be4946", "#dc6f6e", "#d48265"]
    : ["#518b77", "#3EB489", "#67CBA6", "#96DBC2"];
  oo.series = [
    {
      name: name,
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      data: avail
        ? [
            { value: data.availpdfadult, name: map.availpdfadult },
            { value: data.availpdfchild, name: map.availpdfchild },
            { value: data.availmediaadult, name: map.availmediaadult },
            { value: data.availmediachild, name: map.availmediachild },
          ]
        : [
            { value: data.uploadedpdfadult, name: map.uploadedpdfadult },
            { value: data.uploadedpdfchild, name: map.uploadedpdfchild },
            { value: data.uploadedmediachild, name: map.uploadedmediachild },
            { value: data.uploadedmediaadult, name: map.uploadedmediaadult },
          ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ];

  return oo;
};

const IteneraryDashBoard = () => {
  const [uistate, setUiState] = useState<any>({
    vploading: false,
    vpoption: {},
    nvploading: false,
    nvpoption: {},
  });
  const dispatch = useDispatch();

  const fetchBarChart = (type: string) => {
    var option = {
      color: [
        "#8a1e1d",
        "#be4946",
        "#dc6f6e",
        "#d48265",
        "#518b77",
        "#3EB489",
        "#67CBA6",
        "#96DBC2",
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        orient: "horizontal",
        textStyle: {
          width: 70, //set to grid right - item width (default 25) - 5 set tooltip right to grid right
          overflow: "break",
          borderRadius: 12,
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          nameTruncate: {
            maxWidth: 60,
            ellipsis: "...",
          },
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 45,
            overflow: "truncate",
            width: 80,
          },

          axisTick: {
            show: true,
            interval: 0,
          },
          type: "category",
          data: [],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [],
    } as any;

    setUiState((prev: any) => ({
      ...prev,
      [type + "loading"]: true,
      [type + "option"]: {},
    }));
    dispatch(productWiseCountReport({ ticketnature: type })).then((e: any) => {
      const d = e.payload.data;
      const seriese = {
        category: [],
        uploadedpdfadult: [],
        uploadedpdfchild: [],
        uploadedmediaadult: [],
        uploadedmediachild: [],
        availpdfadult: [],
        availpdfchild: [],
        availmediaadult: [],
        availmediachild: [],
      } as any;
      for (let i of d) {
        seriese.category.push(i.product_name);
        seriese.uploadedpdfadult.push(i.total_adult);
        seriese.uploadedpdfchild.push(i.total_child);
        seriese.uploadedmediaadult.push(i.total_code_adult);
        seriese.uploadedmediachild.push(i.total_code_child);
        seriese.availpdfadult.push(i.total_adult - i.total_downloaded_adult);
        seriese.availpdfchild.push(i.total_child - i.total_downloaded_child);
        seriese.availmediaadult.push(
          i.total_code_adult - i.total_downloaded_code_adult
        );
        seriese.availmediachild.push(
          i.total_code_child - i.total_downloaded_code_child
        );
      }
      option.xAxis[0].data = seriese.category;

      const rp = {
        uploadedpdfadult: seriese.uploadedpdfadult.reduce(
          (el: number, total: number) => el + total,
          0
        ),
        uploadedpdfchild: seriese.uploadedpdfchild.reduce(
          (el: number, total: number) => el + total,
          0
        ),
        uploadedmediaadult: seriese.uploadedmediaadult.reduce(
          (el: number, total: number) => el + total,
          0
        ),
        uploadedmediachild: seriese.uploadedmediachild.reduce(
          (el: number, total: number) => el + total,
          0
        ),
        availpdfadult: seriese.availpdfadult.reduce(
          (el: number, total: number) => el + total,
          0
        ),
        availpdfchild: seriese.availpdfchild.reduce(
          (el: number, total: number) => el + total,
          0
        ),
        availmediaadult: seriese.availmediaadult.reduce(
          (el: number, total: number) => el + total,
          0
        ),
        availmediachild: seriese.availmediachild.reduce(
          (el: number, total: number) => el + total,
          0
        ),
      };
      for (let j of [
        "uploadedpdfadult",
        "uploadedpdfchild",
        "uploadedmediaadult",
        "uploadedmediachild",
      ]) {
        option.series.push({
          name: map[j],
          type: "bar",
          stack: "uploaded",
          barMaxWidth: 30,
          label: {
            show: true,
            formatter: (params: any) => (params.value > 0 ? params.value : ""),
          },
          emphasis: {
            focus: "series",
          },
          data: seriese[j],
        });
      }
      for (let j of [
        "availpdfadult",
        "availpdfchild",
        "availmediaadult",
        "availmediachild",
      ]) {
        option.series.push({
          name: map[j],
          type: "bar",
          stack: "available",
          barMaxWidth: 20,
          label: {
            show: true,
            formatter: (params: any) => (params.value > 0 ? params.value : ""),
          },
          emphasis: {
            focus: "series",
          },
          data: seriese[j],
        });
      }
      setUiState((prev: any) => ({
        ...prev,
        [type + "loading"]: false,
        [type + "option"]: option,
        [type]: rp,
      }));
    });
  };

  useEffect(() => {
    fetchBarChart("vp");
    fetchBarChart("nvp");
  }, []);

  interface DataType {
    billNo: string;
    date: string;
    tour: string;
    exQty: string;
    pickup: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Bill No.",
      dataIndex: "billNo",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Tour",
      dataIndex: "tour",
    },
    {
      title: "Ex Qty",
      dataIndex: "exQty",
    },
    {
      title: "Pickup",
      dataIndex: "pickup",
    },
  ];

  const dataShare: DataType[] = [
    {
      billNo: "356121",
      date: "24/12/23",
      tour: "Ferrari Car World",
      exQty: "$654",
      pickup: "822",
    },
  ];
  const [loading, setLoading] = useState(false);
  interface TableParams {
    pagination?: any;
    sortField?: SorterResult<any>["field"];
    sortOrder?: SorterResult<any>["order"];
    filters?: Parameters<GetProp<TableProps, "onChange">>[1];
  }
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [data, setData] = useState<DataType[]>();
  const handleTableChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setData([]);
    // }
  };

  const approvalData = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];

  const approvalDataItem = [
    {
      color: "#00B54A",
      text: "Ben Tossell Need approval for edit in expense",
      date: "Just Now",
    },
    {
      color: "#0FB9C5",
      text: "Ben Tossell Need approval for edit in expense",
      date: "Thu, 10 Mar",
    },
    {
      color: "#826AF9",
      text: "Ben Tossell Need approval for edit in expense",
      date: "3 days ago",
    },
    {
      color: "#faad14",
      text: "Ben Tossell Need approval for edit in expense",
      date: "6 days ago",
    },
  ];

  return (
    <>
      <div className="dashBoardCard">
        <Row gutter={16}>
          <Col span={7} className="">
            <Card
              loading={uistate.vploading}
              title="Total Sales"
              bordered={false}
            ></Card>
          </Col>
          <Col span={7} className="topChartCard">
            <Card
              loading={uistate.vploading}
              title="Total Revenue"
              bordered={false}
            ></Card>
          </Col>
          <Col span={10} className="topChartCard">
            <Card
              loading={uistate.vploading}
              title="Total Receivables"
              bordered={false}
            ></Card>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={14}>
            <Card
              loading={uistate.vploading}
              title="Pending Operations"
              bordered={false}
            >
              <Table
                columns={columns}
                dataSource={dataShare}
                loading={loading}
                onChange={handleTableChange}
              />
            </Card>
          </Col>
          <Col span={10}>
            <Card
              loading={uistate.nvploading}
              title="Total Payables"
              bordered={false}
            ></Card>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={14}>
            <Card
              loading={uistate.nvploading}
              title="Topl Expenses"
              bordered={false}
            ></Card>
          </Col>
          <Col span={10}>
            <Card
              loading={uistate.nvploading}
              title="Topl Expenses"
              bordered={false}
            ></Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={14}>
            <Card
              loading={uistate.nvploading}
              title="Income and Expense"
              bordered={false}
            ></Card>
          </Col>
          <Col span={10}>
            <Card
              loading={uistate.nvploading}
              title="Approvals"
              bordered={false}
            >
              <List
                itemLayout="horizontal"
                dataSource={approvalDataItem}
                className="approvalList"
                renderItem={(item) => (
                  <List.Item>
                    <div
                      className="dots"
                      style={{
                        backgroundColor: item.color,
                      }}
                    ></div>
                    <div>
                      <Text type="secondary" className="font-weight-600">
                        {item.text}
                      </Text>
                      <Text type="secondary" className="d-block">
                        {item.date}
                      </Text>
                    </div>
                    <div className="ml-auto">
                      <Button
                        type="text"
                        icon={
                          <CheckOutlined
                            className="success-color"
                            style={{ fontSize: "20px" }}
                            type="danger"
                            color="danger"
                          />
                        }
                      />

                      <Button
                        type="text"
                        icon={
                          <CloseOutlined
                            className="danger-color"
                            style={{ fontSize: "20px" }}
                          />
                        }
                      />
                    </div>
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export { IteneraryDashBoard };
