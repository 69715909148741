import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './efficient-theme.scss'
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { setupStore } from './app/store'

export const store = setupStore()




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const A = ()=><div style={{background: '#3a3a3a',height: '100vh', width: '100%'}}> 

<div style={{width: '0', height: '0'}}>

<Provider store={store}>
   <App />
</Provider>
</div>
</div>;
//root.render(<A />)
root.render(
  <Provider store={store}>
  <App />
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
