import React, { useState } from "react";
import type { CascaderProps } from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Select,
} from "antd";
import CommonDataTable from "../../../common/CommonDataTable";

const { Option } = Select;

interface DataNodeType {
  value: string;
  label: string;
  children?: DataNodeType[];
}

const residences: CascaderProps<DataNodeType>["options"] = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const HotelOTH: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const namePrefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">Mr.</Option>
        <Option value="87">Ms.</Option>
      </Select>
    </Form.Item>
  );

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        className="mainCardDetailsForm createCustomerForm"
        initialValues={{
          residence: ["zhejiang", "hangzhou", "xihu"],
          prefix: "86",
        }}
        scrollToFirstError
      >
        <Form.Item className="cardTextfieldBox" name="hotel" label="Hotel">
          <Select placeholder="select Hotel" className="cardInputfield">
            <Option value="male">Paramount Hotel</Option>
            <Option value="female">Hotel Nirvana</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Check-In" className="cardTextfieldBox">
          <DatePicker className="cardInputfield" />
        </Form.Item>

        <Form.Item label="Check-Out" className="cardTextfieldBox">
          <DatePicker className="cardInputfield" />
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox"
          name="adult"
          label="Rooms Required"
        >
          <Input className="cardInputfield" />
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox"
          name="roomType"
          label="Room Type"
        >
          <Select placeholder="select Room Type" className="cardInputfield">
            <Option value="male">Deluxe Rooms</Option>
            <Option value="female">Premium Rooms</Option>
          </Select>
        </Form.Item>

        {/* <Form.Item className="cardTextfieldBox" name="country" label="Country">
        <Select placeholder="select Country" className="cardInputfield">
          <Option value="male">UAE</Option>
          <Option value="female">Saudi Arabia</Option>
        </Select>
      </Form.Item>

      <Form.Item className="cardTextfieldBox" name="city" label="City">
        <Select placeholder="select Tour Type" className="cardInputfield">
          <Option value="male">Dubai</Option>
          <Option value="female">Abu Dhabi</Option>
        </Select>
      </Form.Item>



      <div className="cardTextfieldBox dFlex multipleTextfield">
        <Form.Item className="cardTextfieldBox" name="adult" label="Adult">
          <Input className="cardInputfield"/>
        </Form.Item>
        <Form.Item className="cardTextfieldBox" name="child" label="Child">
          <Input className="cardInputfield"/>
        </Form.Item>
        <Form.Item className="cardTextfieldBox" name="infant" label="Infant">
          <Input className="cardInputfield"/>
        </Form.Item>
      </div>

      <div className="cardTextfieldBox dFlex timingInfoBox multipleTextfield">
        <Form.Item className="cardTextfieldBox" name="cost" label="Cost">
          <Input className="cardInputfield"/>
        </Form.Item>
        <Form.Item className="cardTextfieldBox" name="sell" label="Sell">
          <Input className="cardInputfield"/>
        </Form.Item>
      </div>

      <div className="cardTextfieldBox dFlex timingInfoBox multipleTextfield">
       
        <Form.Item className="cardTextfieldBox buttonFieldBox" name="totalRooms" label="Total Rooms">
          <Button type="primary" className='smallIconButton' htmlType="button">
            -
          </Button>
          <Input className="cardInputfield"/>
          <Button type="primary" className='smallIconButton' htmlType="button">
            +
          </Button>
        </Form.Item>
      </div>

      <Form.Item className="cardTextfieldBox" name="supplier" label="Supplier">
        <Select placeholder="select Supplier" className="cardInputfield">
          <Option value="male">Rayna Tourism</Option>
          <Option value="female">Effecient Tourism</Option>
        </Select>
      </Form.Item>

      <Form.Item className="cardTextfieldBox" name="leadPassenger" label="Lead Passenger">
        <Input className="cardInputfield"/>
      </Form.Item>

      <Form.Item className="cardTextfieldBox" name="remarks" label="Remarks">
        <Input className="cardInputfield"/>
      </Form.Item>

      */}
        <Form.Item {...tailFormItemLayout} className="bottomButtonBox w100">
          <Button type="default" htmlType="reset">
            Clear
          </Button>
          <Button type="primary" htmlType="submit">
            Add Hotel
          </Button>
        </Form.Item>
      </Form>
      <CommonDataTable />
    </>
  );
};

export default HotelOTH;
