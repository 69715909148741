import React, { useEffect, useState } from 'react';
import CommonDataTable from '../../common/CommonDataTable';

export const EditAssignCollection = () => {
    return (
        <>
            <CommonDataTable />
        </>
    )
}

export default EditAssignCollection;
