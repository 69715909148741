import React, { useState } from "react";
import type { CascaderProps } from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Select,
} from "antd";

const { Option } = Select;

interface DataNodeType {
  value: string;
  label: string;
  children?: DataNodeType[];
}

const residences: CascaderProps<DataNodeType>["options"] = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const CustomerDetails: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const namePrefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">Mr.</Option>
        <Option value="87">Ms.</Option>
      </Select>
    </Form.Item>
  );

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const [selectedResidenceType, setSelectedResidenceType] =
    useState<string>("Hotel");

  const onResidenceTypeChange = (value: string) => {
    setSelectedResidenceType(value);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      className="mainCardDetailsForm createCustomerForm"
      initialValues={{
        residence: ["zhejiang", "hangzhou", "xihu"],
        prefix: "86",
      }}
      scrollToFirstError
    >
      <Form.Item
        name="residenceType"
        label="Residence Type"
        className="cardTextfieldBox residentBox"
        rules={[{ required: true, message: "Please select Residence!" }]}
      >
        <Select
          defaultValue={"Hotel"}
          className="cardInputfield"
          onChange={onResidenceTypeChange}
        >
          <Option value="Hotel">Hotel</Option>
          <Option value="Residence">Residence</Option>
          <Option value="NDR">NDR</Option>
        </Select>
      </Form.Item>

      <Form.Item className="totalCredit">
        {selectedResidenceType === "Hotel" && (
          <div className="cardRowLabelSection">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={6}>
                <div className="viewLabel text-right">
                  <span>Total Credit Balance</span>
                </div>
                <div className="viewValue totalBalance text-right">
                  <span>AED 800.00</span>
                </div>
              </Col>
              <Col span={6}>
                <div className="viewLabel text-right">
                  <span>Credits Balance</span>
                </div>
                <div className="viewValue creditBalance text-right">
                  <span>AED 0.00</span>
                </div>
              </Col>
              <Col span={6}>
                <div className="viewLabel text-right">
                  <span>Credit Limit Balance</span>
                </div>
                <div className="viewValue creditLimitBalance text-right">
                  <span>AED 2000.00</span>
                </div>
              </Col>
              <Col span={6}>
                <div className="viewLabel text-right">
                  <span>Credit Limit Used</span>
                </div>
                <div className="viewValue limitUsed text-right">
                  <span>AED 1200.00</span>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Form.Item>

      <Form.Item
        className="cardTextfieldBox"
        name="guestName"
        label="Guest Name"
      >
        <Input
          addonBefore={namePrefixSelector}
          style={{ width: "100%" }}
          className="cardInputfield"
        />
      </Form.Item>

      <Form.Item className="cardTextfieldBox" name="country" label="Country">
        <Select placeholder="select Country" className="cardInputfield">
          <Option value="male">Saudi Arabia</Option>
          <Option value="female">Dubai</Option>
          <Option value="other">UAE</Option>
        </Select>
      </Form.Item>

      <Form.Item className="cardTextfieldBox" name="Email" label="Email">
        <Input className="cardInputfield" />
      </Form.Item>

      <Form.Item
        className="cardTextfieldBox"
        name="phoneNumber"
        label="Guest Contact Number"
        rules={[{ required: true, message: "Please input your phone number!" }]}
      >
        <Input addonBefore={prefixSelector} className="cardInputfield" />
      </Form.Item>

      <Form.Item
        className="cardTextfieldBox"
        name="followUpPhoneNumber"
        label="Follow up Contact Number"
        rules={[{ required: true, message: "Please input your phone number!" }]}
      >
        <Input
          addonBefore={prefixSelector}
          style={{ width: "100%" }}
          className="cardInputfield"
        />
      </Form.Item>
      <Form.Item className="cardTextfieldBox" name="idNumber" label="ID Number">
        <Input className="cardInputfield" />
      </Form.Item>
      {selectedResidenceType === "Hotel" && (
        <>
          <Form.Item
            className="cardTextfieldBox"
            name="hotelName"
            label="Hotel Name"
          >
            <Input className="cardInputfield" />
          </Form.Item>

          <Form.Item
            className="cardTextfieldBox"
            name="hotelAddress"
            label="Hotel Address"
          >
            <Input className="cardInputfield" />
          </Form.Item>

          <Form.Item
            className="cardTextfieldBox"
            name="hotelRoomNumber"
            label="Hotel Room Number"
          >
            <Input className="cardInputfield" />
          </Form.Item>
          <Form.Item
            className="cardTextfieldBox"
            name="hotelContactNumber"
            label="Hotel Contact Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input addonBefore={prefixSelector} className="cardInputfield" />
          </Form.Item>

          <Form.Item
            className="cardTextfieldBox"
            name="postalCode"
            label="Postal Code"
          >
            <Input className="cardInputfield" />
          </Form.Item>

          <Form.Item className="cardTextfieldBox" name="city" label="City">
            <Select placeholder="select Country" className="cardInputfield">
              <Option value="male">Saudi Arabia</Option>
              <Option value="female">Dubai</Option>
              <Option value="other">UAE</Option>
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item className="cardTextfieldBox" name="address" label="Address">
        <Input className="cardInputfield" />
      </Form.Item>

      <Form.Item className="cardTextfieldBox" name="company" label="Company">
        <Input className="cardInputfield" />
      </Form.Item>

      <Form.Item
        className="cardTextfieldBox"
        name="designation"
        label="Designation"
      >
        <Input className="cardInputfield" />
      </Form.Item>

      <Form.Item
        className="cardTextfieldBox"
        name="vip"
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Checkbox
              onChange={(e) => console.log("Checked:", e.target.checked)}
            >
              VIP
            </Checkbox>
          </div>
        }
      >
        <Input className="cardInputfield" />
      </Form.Item>

      <div className="guranteeDetails w100">
        <span>Gurantee Details</span>
      </div>

      <Form.Item
        className="cardTextfieldBox"
        name="guranteeType"
        label="Gurantee Type"
      >
        <Select placeholder="select" className="cardInputfield">
          <Option value="male">Cash</Option>
          <Option value="female">Cheque</Option>
          <Option value="other">DD</Option>
        </Select>
      </Form.Item>
      {selectedResidenceType === "NDR" && (
        <>
          <Form.Item
            className="cardTextfieldBox"
            name="chequeNumber"
            label="Cheque Number"
          >
            <Input className="cardInputfield" />
          </Form.Item>

          <Form.Item
            className="cardTextfieldBox"
            name="bankName"
            label="Bank Name"
          >
            <Input className="cardInputfield" />
          </Form.Item>

          <Form.Item className="cardTextfieldBox" name="sl" label="SL">
            <Input className="cardInputfield" />
          </Form.Item>
        </>
      )}

      <Form.Item className="cardTextfieldBox" name="Amount" label="Amount">
        <Input className="cardInputfield" />
      </Form.Item>
    </Form>
  );
};

export default CustomerDetails;
