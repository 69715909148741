import React, { useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Flex,
  Typography,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPassrequestApiFunction } from "./slice-login";
import Icon from "../common/Icon";
import Title from "antd/es/typography/Title";

const ForgotPass = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { resetrequestloading, data } = useSelector(
    (state: any) => state.login
  );
  const { Text, Link } = Typography;
  const { loading, data: any } = useSelector((state: any) => state.login);
  const onFinish = (values: any) => {
    dispatch(resetPassrequestApiFunction(values.email));
  };

  return (
    <Row className="loginBackground loginPage">
      <Col span={12} className="leftPlane" />
      <Col span={12} className="loginContainer">
        <div className="vectorPlane">
          <img alt="" src="assets/images/plane.svg" />
        </div>
        <div className="vectorMonument">
          <img alt="" src="assets/images/monuments.svg" />
        </div>
        <div className="mt-4 px-4">
          <img className="w-100 logo" alt="" src="assets/images/logo.png" />
        </div>
        <div className="loginbox">
        <Title level={1} className="primary-color mt-5 font-weight-400">
        Forgot Password !
        </Title>      
        <br />
        <div className="">
          <Form
            name="normal_login"
             className="mx-4"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={
                  <Icon
                    icon="et-key"
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      padding: "1px 10px",
                    }}
                  />
                }
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Flex vertical align="center" justify="end" className="mt-5">
                <Flex vertical align="end" justify="end">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className="py-4 px-5"
                    loading={loading}
                  >
                    Submit
                  </Button>

                  <Button href="#/login" type="link" className="px-0">
                    <Text type="secondary">Login</Text>
                  </Button>
                </Flex>
              </Flex>
            </Form.Item>
          </Form>
        </div>
        </div>
      </Col>
    </Row>
  );
};

export { ForgotPass };
