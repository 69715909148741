import React, { useState } from "react";
import type { CascaderProps } from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  Flex,
} from "antd";
import CommonDataTable from "../../../common/CommonDataTable";
const { Title, Text } = Typography;
const { Option } = Select;

interface DataNodeType {
  value: string;
  label: string;
  children?: DataNodeType[];
}

const residences: CascaderProps<DataNodeType>["options"] = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const TourInformation: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const namePrefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">Mr.</Option>
        <Option value="87">Ms.</Option>
      </Select>
    </Form.Item>
  );

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const [selectTourType, setSelectTourType] = useState<string>("");
  const onSelectTourTypeChange = (value: string) => {
    setSelectTourType(value);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        className="mainCardDetailsForm createCustomerForm"
        initialValues={{
          residence: ["zhejiang", "hangzhou", "xihu"],
          prefix: "86",
        }}
        scrollToFirstError
      >
        <Form.Item className="cardTextfieldBox" name="city" label="City">
          <Select placeholder="select City" className="cardInputfield">
            <Option value="male">Abu Dhabi City Tour</Option>
            <Option value="female">Dubai Half Day Tour</Option>
          </Select>
        </Form.Item>
        <Form.Item
          className="cardTextfieldBox"
          name="tourType"
          label="Tour Type"
        >
          <Select
            placeholder="select Tour Type"
            className="cardInputfield"
            onChange={onSelectTourTypeChange}
          >
            <option value="Seat In Coach">Seat In Coach</option>
            <Option value="Private">Private</Option>
            <Option value="Booking">Booking</Option>
            <Option value="Non Operational Product">
              Non Operational Product
            </Option>
          </Select>
        </Form.Item>

        <Form.Item className="cardTextfieldBox" name="tou" label="Tour">
          <Select placeholder="select Tour" className="cardInputfield">
            <Option value="male">Abu Dhabi City Tour</Option>
            <Option value="female">Dubai Half Day Tour</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Tour Date" className="cardTextfieldBox">
          <DatePicker style={{ width: "100%" }} className="cardInputfield" />
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox buttonFieldBox checkboxBottomLabelBox"
          name="timeslot"
          label="Timing"
        >
          <Input className="cardInputfield" />
          <Button type="primary" htmlType="submit">
            Slot
          </Button>
          <div className="checkboxLabelBottom">
            <Checkbox className="cardTextfieldBox">Real Time</Checkbox>
          </div>
        </Form.Item>

        <div className="cardTextfieldBox dFlex multipleTextfield">
          <Form.Item className="cardTextfieldBox" name="adult" label="Adult">
            <Select
              placeholder="select Adult"
              className="cardInputfield"
              defaultValue="0"
            >
              <Option value="male">1</Option>
              <Option value="female">2</Option>
            </Select>
          </Form.Item>
          <Form.Item className="cardTextfieldBox" name="child" label="Child">
            <Select className="cardInputfield" defaultValue="0">
              <Option value="0" selected>
                0
              </Option>
              <Option value="female">2</Option>
            </Select>
          </Form.Item>
          <Form.Item className="cardTextfieldBox" name="infant" label="Infant">
            <Select
              placeholder="select Infant"
              defaultValue="0"
              className="cardInputfield"
            >
              <Option value="male">1</Option>
              <Option value="female">2</Option>
            </Select>
          </Form.Item>
        </div>

        {selectTourType === "Private" && (
          <div className="cardTextfieldBox dFlex multipleTextfield">
            <Form.Item
              className=" w-100"
              name="pickupTime"
              label={<Checkbox>Pickup Time</Checkbox>}
            >
              <Flex className="w-100" align="flex-end">
                <Input className="cardInputfield" />
                <Text
                  type="secondary"
                  className="mx-2"
                  style={{ wordBreak: "keep-all" }}
                >
                  Hrs
                </Text>
                <Input className="cardInputfield" />
                <Text type="secondary" className="ml-2">
                  Min
                </Text>
              </Flex>
            </Form.Item>
          </div>
        )}

        {/* <Form.Item className="cardTextfieldBox" name="bookingType" label="Tour Type">
        <Select placeholder="select Tour Type" className="cardInputfield">
          <Option value="male">Private</Option>
          <Option value="female">Booking</Option>
          <Option value="other">Seat in Couch</Option>
          <Option value="other">Non Operational Product</Option>
        </Select>
      </Form.Item> */}

        {/* <Form.Item className="cardTextfieldBox" name="ticket" label="Ticket">
        <Select placeholder="select Ticket" className="cardInputfield">
          <Option value="male">Ferrari Car World</Option>
          <Option value="female">Amusement Park Ticket</Option>
        </Select>
      </Form.Item> */}

        {/* <div className="cardTextfieldBox dFlex timingInfoBox multipleTextfield checkboxTopLabelBox">
        <div className="w100 checkboxLabelTop">
        <Checkbox className="cardTextfieldBox">Pickup Time</Checkbox>
        </div>
        <Form.Item className="cardTextfieldBox" name="hrs" label="">
          <Input className="cardInputfield"/>
          <span className='suffixLabel'>Hrs.</span>
        </Form.Item>
        <Form.Item className="cardTextfieldBox" name="mins" label="">
          <Input className="cardInputfield"/>
          <span className='suffixLabel'>Mins.</span>
        </Form.Item>
      </div>
      <Form.Item className="cardTextfieldBox" name="supplier" label="Supplier">
        <Select placeholder="select Supplier" className="cardInputfield">
          <Option value="male">Rayna Tourism</Option>
          <Option value="female">Effecient Tourism</Option>
        </Select>
      </Form.Item>
       */}
        <Form.Item className="cardTextfieldBox" name="remarks" label="Remarks">
          <Input className="cardInputfield" />
        </Form.Item>
        <Form.Item {...tailFormItemLayout} className="bottomButtonBox w100">
          <Button
            type="default"
            color="default"
            variant="filled"
            htmlType="reset"
          >
            Clear
          </Button>
          <Button type="primary" htmlType="submit">
            Add Tour Information
          </Button>
        </Form.Item>
      </Form>
      <CommonDataTable />
    </>
  );
};

export default TourInformation;
