import React, { useEffect, useState } from "react";
import CommonDataTable from "../../common/CommonDataTable";
import type { CascaderProps } from "antd";
import {
  CloseOutlined,
  LinkOutlined,
  PaperClipOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Col,
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Flex,
} from "antd";
import Title from "antd/es/typography/Title";

const { Option } = Select;

export const EditCollection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  return (
    <>
      <CommonDataTable />
      <Flex justify="end">
        <Button color="danger" onClick={showModal} variant="link" danger>
          <Title level={3} className="mb-0 mt-0 danger-color font-weight-400">
            + Add Collection
          </Title>
        </Button>
      </Flex>

      <Modal
        title="Add Collection"
        className="collectionModal textModal"
        open={isModalOpen}
        onOk={handleOk}
        footer={[
          <Button
            key="back"
            type="default"
            color="default"
            variant="filled"
            onClick={handleCancel}
          >
            Clear
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            onClick={handleOk}
          >
            Assign Supplier
          </Button>,
        ]}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          className="mainCardDetailsForm mt-4"
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >
          <Form.Item
            className="cardTextfieldBox"
            name="collectionType"
            label="Collection Type"
          >
            <Select className="cardInputfield" defaultValue={"Direct Payment"}>
              <Option value="assign">Assign Collection</Option>
              <Option value="client">Client Collection</Option>
              <Option value="direct">Direct Payment</Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="cardTextfieldBox"
            name="selectAssignee"
            label="Select Assignee"
          >
            <Select placeholder="Payment Type" className="cardInputfield">
              <Option value="PartialPayment">Partial Payment</Option>
              <Option value="female">Partial Payment</Option>
            </Select>
          </Form.Item>

          <Form.Item className="cardTextfieldBox" name="Amount" label="Amount">
            <Input className="cardInputfield" />
          </Form.Item>

          <Form.Item
            className="cardTextfieldBox"
            name="paymentMode"
            label="Payment Mode"
          >
            <Select placeholder="select Mode" className="cardInputfield">
              <Option value="male">Cheque</Option>
              <Option value="female">Cash</Option>
            </Select>
          </Form.Item>

          <Form.Item
            className="cardTextfieldBox"
            name="paymentMode"
            label="Select Account"
          >
            <Select placeholder="Select Account" className="cardInputfield">
              <Option value="male">Deepak Citi</Option>
              <Option value="female">Cash</Option>
            </Select>
          </Form.Item>

          <Form.Item
            className="cardTextfieldBox"
            name="chequeNumber"
            label="Transaction Number"
          >
            <Input className="cardInputfield" />
          </Form.Item>
          <Form.Item label="Date" className="cardTextfieldBox">
            <DatePicker className="cardInputfield" />
          </Form.Item>
          <Form.Item
            className="cardTextfieldBox buttonFieldBox fileButton"
            name="attachment"
            label="Attachment"
          >
            <Input className="cardInputfield" />
            <Button type="primary" htmlType="submit">
              <PaperClipOutlined className="rotate" />
              Attach File
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditCollection;
