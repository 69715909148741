import React, { useEffect, useState } from "react";
import {
  GroupOutlined,
  FileOutlined,
  BellOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Avatar,
  Badge,
  Popover,
  Button,
  Col,
  Layout,
  List,
  Row,
  Space,
  theme,
  Descriptions,
  Typography,
  Flex,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dologout, sessionVerifyApi } from "../security/slice-login";
import { getAllMasterType } from "./slice-master";
import Title from "antd/es/typography/Title";

function parseJwt(token: any) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const { Header } = Layout;
const { Text, Link } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const HeaderComponent = ({ setCollapsed, collapsed }: any) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  //check for login token and redirect to login
  const { userinfo, data } = useSelector((state: any) => state.login);
  useEffect(() => {
    if (!data || data.lenght < 0) nav("/login");
  }, [data]);
  useEffect(() => {
    dispatch(getAllMasterType());
  }, []);
  //call session verify api
  useEffect(() => {
    dispatch(sessionVerifyApi());
    const intervalId = setInterval(() => {
      dispatch(sessionVerifyApi());
    }, 150000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Header style={{ background: colorBgContainer }}>
      <Row className="position-relative mrl-0 h-100">
        <Col xs={2} xl={2} className="sliderButton">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
        </Col>
        <Col xs={2} xl={0} className="LogoMobileView">
          <span></span>
        </Col>
        <Col xs={20} xl={22} className="rightHeaderIcons h-100">
          <Popover
            title="Profile"
            content={
              <>
                <Descriptions style={{ width: "244px" }} column={1}>
                  <Descriptions.Item label="Email">
                    {userinfo?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Role">
                    {userinfo?.userRole}
                  </Descriptions.Item>
                </Descriptions>
                <Button
                  type="text"
                  icon={<LogoutOutlined />}
                  onClick={() => {
                    dispatch(dologout(""));
                  }}
                >
                  Logout
                </Button>
              </>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Badge count={1}>
              <Avatar shape="square" icon={<BellOutlined />} />
            </Badge>
            <SettingOutlined className="settingsBox" />
            <Avatar
              className="userDetailIcon"
              shape="square"
              icon={<UserOutlined />}
            />
            <Flex align="center" justify="center">
              <Flex align="start" vertical>
                <Text className="welcomeLabel">Welcome!</Text>
                <Title level={5} className="mt-0 mb-0">
                  {" "}
                  {userinfo?.userName}{" "}
                </Title>
              </Flex>
              <DownOutlined className="ml-3" />
            </Flex>
          </Popover>
          <Popover
            content={
              <>
                <Descriptions style={{ width: "244px" }} column={1}>
                  <Descriptions.Item label="Email">
                    {userinfo?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Role">
                    {userinfo?.userRole}
                  </Descriptions.Item>
                </Descriptions>
                <Button
                  type="text"
                  icon={<LogoutOutlined />}
                  onClick={() => {
                    dispatch(dologout(""));
                  }}
                >
                  Logout
                </Button>
              </>
            }
            title="Profile"
            trigger="click"
            arrow={true}
          ></Popover>
        </Col>
      </Row>
    </Header>
  );
};
export { HeaderComponent };