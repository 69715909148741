import React, { useEffect, useState } from "react";
import CardBox from "../common/CardBox";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  DatePicker,
  Form,
  GetProp,
  Input,
  Select,
  Table,
  TableProps,
  Typography,
} from "antd";
import { SearchOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType, SorterResult } from "antd/es/table/interface";
import qs from "qs";
import CommonDataTable from "../common/CommonDataTable";
import { useNavigate } from "react-router-dom";

interface DataType {
  name: {
    first: string;
    last: string;
  };
  gender: string;
  email: string;
  login: {
    uuid: string;
  };
}

interface TableParams {
  pagination?: any;
  sortField?: SorterResult<any>["field"];
  sortOrder?: SorterResult<any>["order"];
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}
const { Title, Text } = Typography;
const columns: ColumnsType<DataType> = [
  {
    title: "Booking Number",
    dataIndex: "booking_number",
    sorter: true,
    render: (booking_number) => `${booking_number}`,
    width: "20%",
  },
  {
    title: "Email",
    dataIndex: "email",
    filters: [
      { text: "Male", value: "male" },
      { text: "Female", value: "female" },
    ],
    width: "20%",
  },
  {
    title: "Customer Name",
    dataIndex: "text",
  },
  {
    title: "Created On",
    dataIndex: "text",
  },
  {
    title: "Booking Status",
    dataIndex: "text",
  },
  {
    title: "",
    dataIndex: "Action",
  },
];

const getRandomuserParams = (params: TableParams) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const SearchBooking = () => {
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState<boolean>(false);

  // To disable submit button at the beginning.
  useEffect(() => {
    setClientReady(true);
  }, []);

  const onFinish = (values: any) => {
    console.log("Finish:", values);
  };

  return (
    <Form
      form={form}
      name="search_booking"
      className="search_booking"
      layout="inline"
      onFinish={onFinish}
    >
      <Form.Item
        className="filterTextfieldBox"
        label="Search"
        rules={[
          { required: true, message: "Please input your search keywords!" },
        ]}
      >
        <Input
          suffix={<SearchOutlined />}
          placeholder="Search"
          className="searchInputField"
        />
      </Form.Item>
      <Form.Item
        className="filterTextfieldBox"
        label="Created From"
        rules={[{ required: true, message: "Please select created from!" }]}
      >
        <DatePicker className="searchInputField" />
      </Form.Item>

      <Form.Item
        className="filterTextfieldBox"
        label="Created To"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <DatePicker className="searchInputField" />
      </Form.Item>

      <Form.Item
        className="filterTextfieldBox"
        label="Status"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Select
          className="searchInputField"
          defaultValue="All"
          options={[
            { value: "All", label: "All" },
            { value: "booking_created", label: "Booking created" },
          ]}
        />
      </Form.Item>
      <Form.Item className="filterClearBox" shouldUpdate>
        {() => <Button type="primary" icon={<CloseOutlined />} size="middle" />}
      </Form.Item>
    </Form>
  );
};

const BookingData = () => {
  const navigate = useNavigate();

  const onRowClick = (event: any) => {
    navigate("/home/booking/edit");
  };

  return <CommonDataTable onRowClick={onRowClick} />;
};

export const Booking = () => {
  return (
    <CardBox
      title={
        <div>
          <span>Booking</span>
          <span className="highlightBorder"></span>
        </div>
      }
      extra={
        <Link className="createNewPrimary" to={"/home/booking/create"}>
          <PlusOutlined /> Create Booking
        </Link>
      }
    >
      <Card>
        <SearchBooking />
      </Card>
      <Card>
        <Text type="secondary" className="d-block mb-2">Showing 1 to 10 of 800 records</Text>
        <BookingData />
      </Card>
    </CardBox>
  );
};

export default Booking;
