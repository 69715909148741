import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Form, GetProp, Input, Select, Table, TableProps } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';


const SearchOperationDetails: React.FC = () => {
    const [form] = Form.useForm();
    const [clientReady, setClientReady] = useState<boolean>(false);

    // To disable submit button at the beginning.
    useEffect(() => {
        setClientReady(true);
    }, []);

    const onFinish = (values: any) => {
        console.log('Finish:', values);
    };

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      };

    return (
        <Form 
        {...formItemLayout}
        form={form} name="search_booking" onFinish={onFinish} className='mainCardDetailsForm'>
            <Form.Item className="cardTextfieldBox residentBox"
                label="Search"
                rules={[{ required: true, message: 'Please input your search keywords!' }]}
            >
                <Input suffix={<SearchOutlined />} placeholder="Search" className='searchInputField' />
            </Form.Item>
            <Form.Item className="cardTextfieldBox residentBox"
                label="Date From"
                rules={[{ required: true, message: 'Please select created from!' }]}
            >
                <DatePicker />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Date To"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <DatePicker />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Status"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    defaultValue="All"
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Status"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    defaultValue="All"
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Tour Type"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    defaultValue="All"
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="City"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    placeholder='Select City'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Sold By"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    placeholder='Select'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Group"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                     placeholder='Select'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Hotel"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    defaultValue="All"
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Tour"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                     placeholder='Select'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Supplier"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                    defaultValue="All"
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>


            <Form.Item className="cardTextfieldBox residentBox"
                label="Provider"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                     placeholder='Select'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Market"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                     placeholder='Select'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="Job Status"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                     placeholder='Select'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>

            <Form.Item className="cardTextfieldBox residentBox"
                label="FIT or GIT"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Select
                     placeholder='Select'
                    options={[
                        { value: 'All', label: 'All' },
                        { value: 'booking_created', label: 'Booking created' },
                    ]}
                />
            </Form.Item>
            
            <Form.Item className="bottomButtonBox w100 mb-0">
            <Button type="default" htmlType="reset">
              Clear
            </Button>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>

        
    );
}

export default SearchOperationDetails;