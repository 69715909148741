import React, { useState } from "react";
import type { CascaderProps } from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Select,
} from "antd";
import CommonDataTable from "../../../common/CommonDataTable";

const { Option } = Select;

interface DataNodeType {
  value: string;
  label: string;
  children?: DataNodeType[];
}

const residences: CascaderProps<DataNodeType>["options"] = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const OnlineTour: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const namePrefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">Mr.</Option>
        <Option value="87">Ms.</Option>
      </Select>
    </Form.Item>
  );

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        className="mainCardDetailsForm createCustomerForm"
        initialValues={{
          residence: ["zhejiang", "hangzhou", "xihu"],
          prefix: "86",
        }}
        scrollToFirstError
      >
        <Form.Item className="cardTextfieldBox" name="city" label="City">
          <Input className="cardInputfield" />
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox"
          name="tourGroup"
          label="Tour Group"
        >
          <Select placeholder="select Tour Group" className="cardInputfield">
            <Option value="male">Free Independent Traveler</Option>
            <Option value="female">Group Independent Traveler</Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox"
          name="TransferType"
          label="Transfer"
        >
          <Select placeholder="select Tour Type" className="cardInputfield">
            <Option value="male">Private</Option>
            <Option value="female">Sharing</Option>
          </Select>
        </Form.Item>

        <Form.Item className="cardTextfieldBox" name="tou" label="Tour">
          <Select placeholder="select Tour" className="cardInputfield">
            <Option value="male">Abu Dhabi City Tour</Option>
            <Option value="female">Dubai Half Day Tour</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Date" className="cardTextfieldBox">
          <DatePicker className="cardInputfield" />
        </Form.Item>

        <Form.Item
          className="cardTextfieldBox buttonFieldBox"
          name="timeslot"
          label="Timing"
        >
          <Input className="cardInputfield" />
          <Button type="primary" htmlType="submit">
            Slot
          </Button>
        </Form.Item>

        <div className="cardTextfieldBox dFlex multipleTextfield">
          <Form.Item className="cardTextfieldBox" name="adult" label="Adult">
            <Input className="cardInputfield" />
          </Form.Item>
          <Form.Item className="cardTextfieldBox" name="child" label="Child">
            <Input className="cardInputfield" />
          </Form.Item>
          <Form.Item className="cardTextfieldBox" name="infant" label="Infant">
            <Input className="cardInputfield" />
          </Form.Item>
        </div>

        <Form.Item className="cardTextfieldBox" name="remarks" label="Remarks">
          <Input className="cardInputfield" />
        </Form.Item>
        {/* <Form.Item
          className="cardTextfieldBox"
          name="bookingType"
          label="Booking Type"
        >
          <Select placeholder="select Booking Type" className="cardInputfield">
            <Option value="male">Tours</Option>
            <Option value="female">Tickets</Option>
          </Select>
        </Form.Item> 

        <Form.Item className="cardTextfieldBox" name="ticket" label="Ticket">
          <Select placeholder="select Ticket" className="cardInputfield">
            <Option value="male">Ferrari Car World</Option>
            <Option value="female">Amusement Park Ticket</Option>
          </Select>
        </Form.Item>*/}





        <Form.Item {...tailFormItemLayout} className="bottomButtonBox w100">
          <Button type="default" htmlType="reset">
            Clear
          </Button>
          <Button type="primary" htmlType="submit">
            Add Tour
          </Button>
        </Form.Item>
      </Form>
      <CommonDataTable />
    </>
  );
};

export default OnlineTour;
