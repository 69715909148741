import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  GetProp,
  Input,
  Modal,
  Select,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  TableProps,
} from "antd";
import { SorterResult } from "antd/es/table/interface";
const OperationData: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>["field"];
    sortOrder?: SorterResult<any>["order"];
    filters?: Parameters<GetProp<TableProps, "onChange">>[1];
  }

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["bottomCenter"],
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  interface DataType {
    billNumber: string;
    date: string;
    room: string;
    hotel: string;
    tour: string;
    exQty: string;
    adult: string;
    child: string;
    infant: string;
    pickup: string;
    jobDB: string;
    pCall: string;
    pcl: string;
    remarks: string;
    user: string;
    provider: string;
    sold: string;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: "Bill Number",
      dataIndex: "billNumber",
      sorter: true,
      render: (booking_number: string) => `${booking_number}`,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
    },
    {
      title: "Room",
      dataIndex: "room",
      sorter: true,
    },
    {
      title: "Hotel",
      dataIndex: "hotel",
      sorter: true,
    },
    {
      title: "Tour",
      dataIndex: "tour",
      sorter: true,
    },
    {
      title: "ExQty",
      dataIndex: "exQty",
      sorter: true,
    },
    {
      title: "Adult",
      dataIndex: "adult",
      sorter: true,
    },
    {
      title: "Child",
      dataIndex: "child",
      sorter: true,
    },
    {
      title: "Infant",
      dataIndex: "infant",
      sorter: true,
    },
    {
      title: "Pickup",
      dataIndex: "pickup",
      sorter: true,
    },
    {
      title: "Job DB",
      dataIndex: "jobDB",
      sorter: true,
    },
    {
      title: "P Call",
      dataIndex: "pCall",
      sorter: true,
    },
    {
      title: "PCL",
      dataIndex: "pcl",
      sorter: true,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: true,
    },
    {
      title: "User",
      dataIndex: "user",
      sorter: true,
    },
    {
      title: "Provider",
      dataIndex: "provider",
      sorter: true,
    },
    {
      title: "Sold",
      dataIndex: "sold",
      sorter: true,
    },
  ];

  const [data, setData] = useState<DataType[]>();

  const dataShare: DataType[] = [
    {
      billNumber: "2501287619",
      date: "24/02/24",
      room: "lobby",
      hotel: "Souk Madnar Jumirah",
      tour: "Ferrari Car World",
      exQty: "0",
      adult: "2",
      child: "0",
      infant: "0",
      pickup: "11:00 am",
      jobDB: "--",
      pCall: "0.00",
      pcl: "0.00",
      remarks:
        "Pickup location SOUK madinar jumirah contact +91-934583454 vip guest",
      user: "Jatin Rai",
      provider: "Rayna Tourism",
      sold: "C: 517.5 S: 518.5",
    },
  ];

  const [loading, setLoading] = useState(false);
  const handleTableChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log("Finish:", values);
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  return (
    <>
      <Table<DataType>
        columns={columns}
        dataSource={dataShare}
        pagination={tableParams.pagination}
        loading={loading}
        className="fullWidthDataTable"
        onChange={handleTableChange}
        scroll={{ x: 800 }}
      />
      <Form className="w100">
        <Form.Item className="bottomButtonBox w100 mb-0">
          <Button type="primary" onClick={showModal}>
            Assign Supplier
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Assign Supplier"
        className="collectionModal textModal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Assign Supplier"
        cancelText="Clear"
      >
        <div>
          <Form
          {...formItemLayout}
            form={form}
            name="horizontal_login" 
            onFinish={onFinish}
            className="createCustomerForm mainCardDetailsForm mt-4"
          >
            <Form.Item
              className="cardTextfieldBox residentBox"
              name="Search"
              label="Product Name"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Product Name" className="searchInputField" />
            </Form.Item>

            <Form.Item
              className="cardTextfieldBox residentBox"
              label="Select Supplier"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Select />
            </Form.Item>

            <Form.Item
              className="cardTextfieldBox residentBox"
              name="ProductType"
              label="Product Type"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Product Type" className="searchInputField" />
            </Form.Item>

            <Form.Item
              className="cardTextfieldBox"
              label="Select Service"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Select />
            </Form.Item>

            <Form.Item
              className="cardTextfieldBox"
              label="Remarks"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input placeholder="Remarks" className="searchInputField" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default OperationData;
