import React, { useEffect, useState } from "react";
import CardBox from "../../common/CardBox";
import { CloseOutlined, RollbackOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Button, Card, Col, Collapse, CollapseProps, Row } from "antd";
import { EditCustomerDetails } from "./edit-customer-details";
import EditTourDetails from "./edit-tour-details";
import AmountDetails from "./edit-amount-details";

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "Customer Details",
    children: <EditCustomerDetails />,
  },
  {
    key: "2",
    label: "Tour Information",
    children: <EditTourDetails />,
  },
  {
    key: "3",
    label: "Amount Details",
    children: <AmountDetails />,
  },
];

export const EditBooking = () => {
  return (
    <CardBox
      title={
        <div>
          <span>Booking Number: 2398437</span>
          <span className="highlightBorder"></span>
        </div>
      }
      extra={
        <Link to={"/home/booking"}>
          <Button
            type="primary"
            className="componentCloseIcon"
            icon={<CloseOutlined />}
            size="middle"
          />
        </Link>
      }
    >
      <Collapse
        className="booking-collapse"
        accordion={false}
        defaultActiveKey={[1, 2, 3]}
        items={items}
      />

      <Row className="bottomButtonBox">
        <Col span={12} className="leftButtons">
          <Button color="default" variant="solid" htmlType="reset">
            <RollbackOutlined /> Back
          </Button>
        </Col>
        <Col span={12} className="rightButtons">
          <Button type="primary" htmlType="submit">
            Edit Invoice
          </Button>
          <Button type="primary" htmlType="submit">
            Create Invoice
          </Button>
        </Col>
      </Row>
    </CardBox>
  );
};

export default EditBooking;