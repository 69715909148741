import React from 'react';
import { Tabs } from 'antd';
import TourInformation from './tour-details/tour-information';
import HotelInformation from './tour-details/hotel-information';
import AirlineTicket from './tour-details/airline-ticket';
import OTBService from './tour-details/otb-service';
import InternationalVisa from './tour-details/international-visa';
import Insurance from './tour-details/insurance';
import OnlineTour from './tour-details/online-tour';
import CustomerDetails from './customer-details';
import HotelOTH from './tour-details/hoteal-oth';

const onChange = (key: string) => {
  console.log(key);
};

const items = [
    {
        key: '1',
        label: 'Tour Information',
        children: <TourInformation />
    },
    {
        key: '2',
        label: 'Hotel Information',
        children: <HotelInformation />
    },
    {
        key: '3',
        label: 'Airline Ticket',
        children: <AirlineTicket />
    },
    {
        key: '4',
        label: 'OTB Service',
        children: <OTBService />
    },
    {
        key: '5',
        label: 'International Visa',
        children: <InternationalVisa />
    },
    {
        key: '6',
        label: 'Insurance',
        children: <Insurance />
    },
    {
        key: '7',
        label: 'Hotel OTH',
        children: <HotelOTH />
    },
    {
        key: '8',
        label: 'Online Tour',
        children: <OnlineTour />
    }
];
  
const TourDetails: React.FC = () => (
  <Tabs
    onChange={onChange}
    type="card"
    items={items}
    className='tour-details'
  />
);

export default TourDetails;