import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { stat } from 'fs'
import moment from 'moment'
import { act } from 'react-dom/test-utils'


// // First, create the thunk
// // Define a type for the slice state
// export const hrmsOrganizationProductSearch = ThunkHttpClient.get('hrmsOrganization/search',
//     (data) => ("itinerary/productList?pageSize=1&pageNo=1") )

const transformtotree = (el:any):any => {
    let pid = (el.pid?el.pid+'-': '')+el.id
    return ({id: el.id, key: pid, title:   el.elelment, children: el.children?el.children.map((e:any) => transformtotree({...e, pid: pid})): [] })
  }
      

export const hrmsPermissionJson = createAsyncThunk("hrmsslices/staticdropdown", async (data: any, { dispatch }) => {
    const datad = await fetch('/assets/permission.json', {
      method: 'GET', // or 'PUT'
      headers: { "Content-Type": "application/json" }
    })
    const  dd =  datad.json()
    return dd;
})

// export const itineraryFetchCustomerByEmail = ThunkHttpClient.get('hrmsOrganization/itineraryFetchCustomerByEmail',
//     (data) => ("itinerary/searchCustomer?email="+data.email) )

// export const itineraryFetchHotelByName = ThunkHttpClient.get('hrmsOrganization/itineraryFetchHotelByName',
//     (data) => ("itinerary/searchHotel?name="+data))

// export const itineraryPreview = ThunkHttpClient.get('hrmsOrganization/itineraryFetchHotelByName',
//     (data) => ("itinerary/previewQuotation?quotation_id="+data))

// export const itinerarySaveHotel = ThunkHttpClient.post('hrmsOrganization/itinerarySaveHotel',
//     (data) => ({ url: "itinerary/saveHotel", body:  data}))

//     export const itinerarySaveQuotationDetail = ThunkHttpClient.post('hrmsOrganization/saveQuotation',
//     ({customerid, sendToEmail, body}) => { return { url: "itinerary/saveQuotation?customerId="+customerid+"&sendToEmail="+sendToEmail, body:  body} })

//     export const itinerarySaveCustomerDetail = ThunkHttpClient.post('hrmsOrganization/savitinerarySaveCustomerDetaileQuotation',
//     (body) => { return { url: "itinerary/saveCustomer", body:  body} })

//     export const itinerarysendQuotationToEmail = ThunkHttpClient.get('hrmsOrganization/savitinerarysendQuotationToEmaileQuotation',
//     (body) => "itinerary/sendQuotationToEmail?quotation_id="+body)

//     export const itineraryDeleteProduct = ThunkHttpClient.delete('hrmsOrganization/savitinerarysendQuotationToProductDelete',
//     (id) => "itinerary/deleteHotel?id="+id)

//     export const itineraryDeleteHotel = ThunkHttpClient.delete('hrmsOrganization/savitinerarysendQuotationToHotelDeete',
//     (id) => "itinerary/deleteProduct?id="+id)

//     export const saveProductOfIntenotryproduct = ThunkHttpClient.post('hrmsOrganization/saveProductOfIntenotryproduct',
//     (data) => { return { url: "itinerary/saveProduct", body:  {productName: data.productname, price: data.cost}} })  
    
    

// Define the initial state using that type
const initialState: any = {
    permissionData: []
}

export const hrmsOrganizationSlice = createSlice({
    name: 'hrmsOrganization',
    initialState,
    reducers: {

        

        updateDraftQuotationUiState: (state, action) => {
            state.uotationuistate =  action.payload
        },

        
    },
    extraReducers: (builder) => {
    
        builder.addCase(hrmsPermissionJson.fulfilled, (state, action) => {
            state.permissionDataLoading = false;
            console.log('datad', action.payload.map((e:any)=>transformtotree(e)))
            state.permissionData = action.payload.map((e:any)=>transformtotree(e))
        })
        builder.addCase(hrmsPermissionJson.pending, (state, action) => {
            console.log('datad', action)
            state.permissionData=[];
            state.permissionDataLoading = true;
        })
        builder.addCase(hrmsPermissionJson.rejected, (state, action) => {
            state.permissionDataLoading = false;
        })


      

        
    },
})


export const { } = hrmsOrganizationSlice.actions
export default hrmsOrganizationSlice.reducer