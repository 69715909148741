import path from "path";
import { Children, lazy } from "react";
import hrmsReducer from './component/hrms/organization/slice-organization'
import userReducer from './component/usermanagement/users/slice-user'


const routerNReducer =  [

    // User Management User Component
    {
      path: "/home/user",
      async lazy() {
        let {User} = await import("./component/usermanagement/users/Users");
        return { Component: User };
      },
      reducer: {
        name: 'user',
        reducer:  userReducer
      },
    },

    // User Management Company Component

     {
      path: "/home/company",
      async lazy() {
        let {Company} = await import("./component/usermanagement/company/Company");
        return { Component: Company };
      },
      reducer: {
        name: 'company',
        reducer: () => import('./component/usermanagement/company/slice-company').then((module:any) => module.reducer) 
      },
    }

    // User Management Company Component
    ,
    {
      path: "/home/hrms/",
      async lazy() {
        let {Hrms} = await import("./component/hrms/Hrms");
        return { Component: Hrms };
      },
      children: [
      {path: "/home/hrms/organization/hierarchy",
      async lazy() {
        let {OrganizationHierarchy} = await import("./component/hrms/organization/OrgHierarchy");
        return { Component: OrganizationHierarchy };
      }},
      {path: "/home/hrms/permissionassignment",
      async lazy() {
        let {PermissionAssignment} = await import("./component/hrms/organization/PermissionAssignment");
        return { Component: PermissionAssignment };
      }}],

      reducer: {
        name: 'hrms',
        reducer: hrmsReducer 
      },
    }
    ,    
    
    {
      path: "/home/accounting",
      async lazy() {
        let {Accounting} = await import("./component/accounting/accounting");
        return { Component: Accounting };
      },
      children: [
        {path: "/home/accounting/",
        async lazy() {
          let {AccountingHome} = await import("./component/accounting/accounting-home");
          return { Component: AccountingHome };
        }},
        {path: "/home/accounting/invoices",
        async lazy() {
          let {Invoices} = await import("./component/accounting/invoice/invoices");
          return { Component: Invoices };
        }},
        {path: "/home/accounting/expenses",
        async lazy() {
          let {Expense} = await import("./component/accounting/expenses/expenses");
          return { Component: Expense };
        }},
        {path: "/home/accounting/create-expense",
        async lazy() {
          let {CreateExpense} = await import("./component/accounting/expenses/create-expenses");
          return { Component: CreateExpense };
        }},
        {path: "/home/accounting/create-invoice",
        async lazy() {
          let {CreateInvoice} = await import("./component/accounting/invoice/create-invoices");
          return { Component: CreateInvoice };
        }},

        {
          path: "/home/accounting/reports",
        async lazy() {
          let {Reports} = await import("./component/accounting/reports/reporthome");
          return { Component: Reports };
        }},


        // banking components
        {
          path: "/home/accounting/banking",
          async lazy() {
          let {Banking} = await import("./component/accounting/banking/banking")
          return { Component: Banking }
        }},
        {
          path: "/home/accounting/create-banking",
          async lazy() {
          let {CreateBanking} = await import("./component/accounting/banking/create-banking");
          return { Component: CreateBanking };
        }}, 

        // report components
        {
          path: "/home/accounting/report/profitloss",
          async lazy() {
          let {ProfitLoss} = await import("./component/accounting/reports/report-component/profit-&-loss")
          return { Component: ProfitLoss }
        }},
        {
          path: "/home/accounting/report/cashflow-statement",
          async lazy() {
          let {CashflowStatement} = await import("./component/accounting/reports/report-component/cashflow-statement");
          return { Component: CashflowStatement };
        }}
        ,
        {
          path: "/home/accounting/report/balancesheet",
          async lazy() {
          let {Balancesheet} = await import("./component/accounting/reports/report-component/balancesheet");
          return { Component: Balancesheet };
        }}
        ,
        {
          path: "/home/accounting/report/business-performance-ratio",
          async lazy() {
          let {BusinessPerformanceRatios} = await import("./component/accounting/reports/report-component/business-performance-ratios");
          return { Component: BusinessPerformanceRatios };
        }}
        ,
        {
          path: "/home/accounting/report/cash-flow-forcasting",
          async lazy() {
          let {CashFlowForcasting} = await import("./component/accounting/reports/report-component/cash-flow-forcasting");
          return { Component: CashFlowForcasting };
        }}
        ,
        {
          path: "/home/accounting/report/movement-of-equity",
          async lazy() {
          let {MovementOfEquity} = await import("./component/accounting/reports/report-component/movement-of-equity");
          return { Component: MovementOfEquity };
        }}
        ,
        {
          path: "/home/accounting/report/sales-by-customer",
          async lazy() {
          let {SalesByCustomer} = await import("./component/accounting/reports/report-component/sales-by-customer");
          return { Component: SalesByCustomer };
        }}
        ,
        {
          path: "/home/accounting/report/sales-by-item",
          async lazy() {
          let {SalesByItem} = await import("./component/accounting/reports/report-component/sales-by-item");
          return { Component: SalesByItem };
        }}
        ,
        {
          path: "/home/accounting/report/sales-by-salesperson",
          async lazy() {
          let {SalesBySalesperson} = await import("./component/accounting/reports/report-component/sales-by-salesperson");
          return { Component: SalesBySalesperson };
        }}
        ,
        {
          path: "/home/accounting/report/profitloss",
          async lazy() {
          let {Profit} = await import("./component/accounting/reports/report-component/profit");
          return { Component: Profit };
        }}
        ,
        {
          path: "/home/accounting/report/inventory-summary",
          async lazy() {
          let {InventorySummary} = await import("./component/accounting/reports/report-component/inventory-summary");
          return { Component: InventorySummary };
        }}
        ,
        {
          path: "/home/accounting/report/product-sales-report",
          async lazy() {
          let {ProductSalesReport} = await import("./component/accounting/reports/report-component/product-sales-report");
          return { Component: ProductSalesReport };
        }}
        ,
        {
          path: "/home/accounting/report/stock-summary-report",
          async lazy() {
          let {StockSummaryReport} = await import("./component/accounting/reports/report-component/stock-summary-report");
          return { Component: StockSummaryReport };
        }}
        ,
        {
          path: "/home/accounting/report/customer-balance",
          async lazy() {
          let {CustomerBalance} = await import("./component/accounting/reports/report-component/customer-balance");
          return { Component: CustomerBalance };
        }}
        ,
        {
          path: "/home/accounting/report/invoice-detail",
          async lazy() {
          let {InvoiceDetail} = await import("./component/accounting/reports/report-component/invoice-detail");
          return { Component: InvoiceDetail };
        }}
        ,
        {
          path: "/home/accounting/report/receivable-summary",
          async lazy() {
          let {ReceivableSummary} = await import("./component/accounting/reports/report-component/receivable-summary");
          return { Component: ReceivableSummary };
        }}
        ,
        {
          path: "/home/accounting/report/payment-received",
          async lazy() {
          let {PaymentReceived} = await import("./component/accounting/reports/report-component/payment-received");
          return { Component: PaymentReceived };
        }}
        ,
        {
          path: "/home/accounting/report/time-to-get-paid",
          async lazy() {
          let {TimeToGetPaid} = await import("./component/accounting/reports/report-component/time-to-get-paid");
          return { Component: TimeToGetPaid };
        }}
        ,
        {
          path: "/home/accounting/report/credit-note-detail",
          async lazy() {
          let {CreditNoteDetails} = await import("./component/accounting/reports/report-component/credit-note-details");
          return { Component: CreditNoteDetails };
        }}
        ,
        {
          path: "/home/accounting/report/payable-refund-history",
          async lazy() {
          let {RefundHistory} = await import("./component/accounting/reports/report-component/refund-history");
          return { Component: RefundHistory };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-balances",
          async lazy() {
          let {VendorBalances} = await import("./component/accounting/reports/report-component/vendor-balances");
          return { Component: VendorBalances };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-balance-summary",
          async lazy() {
          let {VendorBalancesSummary} = await import("./component/accounting/reports/report-component/vendor-balances-summary");
          return { Component: VendorBalancesSummary };
        }}
        ,
        {
          path: "/home/accounting/report/ap-aging-summary",
          async lazy() {
          let {ApAgingSummary} = await import("./component/accounting/reports/report-component/ap-aging-summary");
          return { Component: ApAgingSummary };
        }}
        ,
        {
          path: "/home/accounting/report/ap-agign-detail",
          async lazy() {
          let {ApAgingDetail} = await import("./component/accounting/reports/report-component/ap-aging-detail");
          return { Component: ApAgingDetail };
        }}
        ,
        {
          path: "/home/accounting/report/payable-bill-details",
          async lazy() {
          let {BillDetails} = await import("./component/accounting/reports/report-component/bill-details");
          return { Component: BillDetails };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-credit-details",
          async lazy() {
          let {VendorCreditDetails} = await import("./component/accounting/reports/report-component/vendor-credit-details");
          return { Component: VendorCreditDetails };
        }}
        ,
        {
          path: "/home/accounting/report/payment-mode",
          async lazy() {
          let {PaymentMade} = await import("./component/accounting/reports/report-component/payment-made");
          return { Component: PaymentMade };
        }}
        ,
        {
          path: "/home/accounting/report/payable-summary",
          async lazy() {
          let {PayableSummary} = await import("./component/accounting/reports/report-component/payable-summary");
          return { Component: PayableSummary };
        }}
        ,
        {
          path: "/home/accounting/report/payable-detail",
          async lazy() {
          let {PaybleDetail} = await import("./component/accounting/reports/report-component/payble-detail");
          return { Component: PaybleDetail };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-balances",
          async lazy() {
          let {PurchaseByVendor} = await import("./component/accounting/reports/report-component/purchase-by-vendor");
          return { Component: PurchaseByVendor };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-balance-summary",
          async lazy() {
          let {PurchaseByItem} = await import("./component/accounting/reports/report-component/purchase-by-item");
          return { Component: PurchaseByItem };
        }}
        ,
        {
          path: "/home/accounting/report/ap-aging-summary",
          async lazy() {
          let {ExpenseDetails} = await import("./component/accounting/reports/report-component/expense-details");
          return { Component: ExpenseDetails };
        }}
        ,
        {
          path: "/home/accounting/report/ap-agign-detail",
          async lazy() {
          let {ExpenseByCategory} = await import("./component/accounting/reports/report-component/expense-by-category");
          return { Component: ExpenseByCategory };
        }}
        ,
        {
          path: "/home/accounting/report/payable-bill-details",
          async lazy() {
          let {ExpenseByCustomer} = await import("./component/accounting/reports/report-component/expense-by-customer");
          return { Component: ExpenseByCustomer };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-credit-details",
          async lazy() {
          let {ExpenseByProject} = await import("./component/accounting/reports/report-component/expense-by-project");
          return { Component: ExpenseByProject };
        }}
        ,
        {
          path: "/home/accounting/report/payment-mode",
          async lazy() {
          let {ExpenseByEmployee} = await import("./component/accounting/reports/report-component/expense-by-employee");
          return { Component: ExpenseByEmployee };
        }}
        ,
        {
          path: "/home/accounting/report/payable-refund-history",
          async lazy() {
          let {BillableExpenseDetails} = await import("./component/accounting/reports/report-component/billable-expense-details");
          return { Component: BillableExpenseDetails };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-balances",
          async lazy() {
          let {VatReturns} = await import("./component/accounting/reports/report-component/vat-returns");
          return { Component: VatReturns };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-balance-summary",
          async lazy() {
          let {VatAuditReport} = await import("./component/accounting/reports/report-component/vat-audit-report");
          return { Component: VatAuditReport };
        }}
        ,
        {
          path: "/home/accounting/report/ap-aging-summary",
          async lazy() {
          let {ExciesTaxAuditReport} = await import("./component/accounting/reports/report-component/excies-tax-audit-report");
          return { Component: ExciesTaxAuditReport };
        }}
        ,
        {
          path: "/home/accounting/report/vendor-balances",
          async lazy() {
          let {ReconcilationStatus} = await import("./component/accounting/reports/report-component/reconcilation-status");
          return { Component: ReconcilationStatus };
        }}
        // end report component
      
      
    ],


      

      reducer: {
        name: 'accounting',
        reducer: () => import('./component/accounting/slice-accounting').then((module:any) => module.reducer) 
      },
    },

    {
      path: "/home/master",
      async lazy() {
        let {Masters} = await import("./component/master/master");
        return { Component: Masters };
      },

      children: [

        {path: "home",
        async lazy() {
          let {MasterHome} = await import("./component/master/master-home");
          return { Component: MasterHome };
        }},
        {path: "customers",
        async lazy() {
          let {Customers} = await import("./component/master/customerdata/customers");
          return { Component: Customers };
        }},
        {path: "create-customer",
        async lazy() {
          let {CreateCustomer} = await import("./component/master/customerdata/create-customer");
          return { Component: CreateCustomer };
        }},
        {path: "supplydata",
        async lazy() {
          let {Supply} = await import("./component/master/supplydata/supply");
          return { Component: Supply };
        }},
        {path: "create-supply",
        async lazy() {
          let {CreateSupply} = await import("./component/master/supplydata/create-supply");
          return { Component: CreateSupply };
        }},

        {path: "tournticket",
        async lazy() {
          let {TournTickets} = await import("./component/master/ticketsntour/ticketsntour");
          return { Component: TournTickets };
        }},
        {path: "create-tour",
        async lazy() {
          let {CreateTour} = await import("./component/master/ticketsntour/create-tour");
          return { Component: CreateTour };
        }},
        {path: "bankAccounts",
          async lazy() {
            let {bankAccounts} = await import("./component/master/bankAccounts/bankAccounts");
            return { Component: bankAccounts };
          }},
          {path: "create-bankAccounts",
          async lazy() {
            let {CreateBankAccounts} = await import("./component/master/bankAccounts/create-bankAccounts");
            return { Component: CreateBankAccounts };
          }},
          {path: "department",
          async lazy() {
            let {Department} = await import("./component/master/department/department");
            return { Component: Department };
          }},
          {path: "create-department",
          async lazy() {
            let {CreateDepartment} = await import("./component/master/department/create-department");
            return { Component: CreateDepartment };
          }},
  
          {path: "hotels",
          async lazy() {
            let {HotelData} = await import("./component/master/hotelDetails/hotels");
            return { Component: HotelData };
          }},
          {path: "create-hotel",
          async lazy() {
            let {CreateHotels} = await import("./component/master/hotelDetails/create-hotel");
            return { Component: CreateHotels };
          }},
          {path: "VisaDepartment",
            async lazy() {
              let {Visa} = await import("./component/master/visaDepartment/visa");
              return { Component: Visa };
            }},
            {path: "create-Visa",
            async lazy() {
              let {CreateVisa} = await import("./component/master/visaDepartment/create-visa");
              return { Component: CreateVisa };
            }},
    
            {path: "taxation",
            async lazy() {
              let {Tax} = await import("./component/master/taxation/tax");
              return { Component: Tax };
            }},
            {path: "create-tax",
            async lazy() {
              let {CreateTax} = await import("./component/master/taxation/create-tax");
              return { Component: CreateTax };
            }},
      
      
    ],


      

      reducer: {
        name: 'accounting',
        reducer: () => import('./component/accounting/slice-accounting').then((module:any) => module.reducer) 
      },
    }



   // 
   
  ] as any[];


  let reducerDetail = {} as any;

  const routesDetail:any = (routerDetails:any[]) => {
    return routerDetails.map((route:any) => {

        if(route.reducer && route.reducer.name)
        reducerDetail[route.reducer.name] = route.reducer.reducer;

        return {
            path: route.path,
            lazy: route.lazy,
            children: route.children ? routesDetail(route.children) : [],
        }
       });
  }


  export const routesvalues =  () => {
   return routesDetail(routerNReducer) || []; 
  }

  export const reducers = ()=> {
    routesDetail(routerNReducer); 
    return reducerDetail;
  }

